<template>
  <div
    v-tap="analysisData()"
    class="promo-satisfied"
    :style="styleInfo"
  >
    <div 
      v-if="satisfiedItem"
      class="content" 
    >
      <Icon
        name="sui_icon_selected_16px"
        size="16px"
        color="#FA6338"
      />

      <span 
        ref="tips"
        class="middle-tips"
      >
        <template v-if="!isOverflow">
          <span>{{ info.leftTip }}</span>
          <span class="promo-item">
            <img
              class="promo-ico"
              :src="satisfiedItem.icon"
            />
            <TextMarquee
              ref="textMarquee"
              width="2.82rem"
              :autoReset="true"
            >
              <span v-html="satisfiedItem.tip"></span>
            </TextMarquee>
          </span>
          <span>{{ info.rightTip }}</span>
        </template>
        <template v-else>
          <span class="too-long-tip">
            {{ info.wholeLineTooLongTip }}
          </span>
        </template>
      </span>

      <Icon
        name="sui_icon_more_right_12px_2"
        size="12px"
        :is-rotate="locals.GB_cssRight"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import TextMarquee from 'public/src/pages/cart_v2/components/common/TextMarquee.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { computed } from 'vue'

const CSS_CONFIG = {
  'planA': {
    '--bg-color': '#FFF4EE',
  },
  'planB': {
    '--bg-color': '#FFEEEB',
  },
}
export default {
  setup() {
    const { state } = useStore()
    return {
      locals: computed(() => state.locals),
    }
  },
  name: 'PromoSatisfied',
  components: { Icon, TextMarquee },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    satisfiedItem: {
      type: Object,
      default: () => ({})
    },
    abtType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOverflow: false,
    }
  },
  computed: {
    type() {
      return this.satisfiedItem?.type
    },
    isFreeShipping() {
      return this.type === 'freeShipping'
    },
    isSheinClub() {
      return this.type === 'creditReward'
    },
    styleInfo() {
      return {
        ...CSS_CONFIG[this.abtType],
        ...this.getCssConfig()
      }
    },
  },
  watch: {
    'satisfiedItem': {
      handler(val) {
        if (typeof window === 'undefined') return
        if (!val) return
        this.$nextTick(() => {
          this.$refs.textMarquee?.init()
          this.isOverflow = this.checkOverflow(this.$refs.tips)
          setTimeout(() => {
            this.$emit('is-show', this.getSaData())
          }, 0)
        })
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    checkOverflow(el) {
      return el?.clientWidth < el?.scrollWidth
    },
    analysisData() {
      return {
        id: '1-8-1-49',
        data: this.getSaData()
      }
    },
    getSaData() {
      const num = this.info?.lockDealNum || 0
      const isMarquee = this.$refs.textMarquee?.isAnimation
      let lock_deals = ''
      const { lockDeal } = this.satisfiedItem || {}
      if (this.isSheinClub) {
        lock_deals = lockDeal
      } else {
        if (this.isOverflow) {
          lock_deals = `lock_none_${num}_0`
        } else {
          lock_deals = `lock_deals_${lockDeal}_${num}_${isMarquee ? 1 : 0}`
        }
      }
      return {
        mix_type: 'bestdeals',
        lock_deals,
      }
    },
    getCssConfig() {
      if (this.isSheinClub) {
        return {
          '--middle-font-color': '#CB8461',
          '--middle-border-color': '#CB8461',
        }
      }
      if (this.isFreeShipping) {
        return {
          '--middle-font-color': '#198055',
          '--middle-border-color': '#8CBCA5',
        }
      }

      return {
        '--middle-font-color': '#FA6338',
        '--middle-border-color': '#FF9475',
      }
    }
  },
}
</script>

<style lang="less" scoped>
.promo-satisfied {
  position: relative;
  background-color: var(--bg-color);
  height: 30/37.5rem;
  &::after {
    content: ' ';
    position: absolute;
    background-color: var(--bg-color);
    height: 18/37.5rem;
    width: 100%;
  }
  .content {
    width: 100%;
    height: 30/37.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 12/37.5rem;
    .middle-tips {
      margin-left: 8/37.5rem;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .too-long-tip {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .promo-item {
    display: inline-block;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 3/37.5rem;
    color: var(--middle-font-color);
    border-width: 1px;
    border-style: solid;
    border-color: var(--middle-border-color);
    padding: 0 4/37.5rem;
    margin: 0 4/37.5rem;
    box-sizing: border-box;
    max-width: 134/37.5rem;
    height: 18/37.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    width: initial;
    display: flex;
    align-items: center;
    .promo-ico {
      height: 14/37.5rem;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }
}
</style>
