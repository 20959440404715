import { computed } from 'vue'
import { onMountedOrActivated } from 'public/src/pages/cart_v2/hooks/onMountedOrActivated.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { usePriceChangeCache } from './usePriceChangeCache.js'

export function usePriceChangeState() {
  const { commit, state } = useStore()
  const showPopoverMax = computed(() => state.locals.PRICE_CHANGE_SHOW_POPOVER_MAX || 3)
  const showPopover = computed(() => state.priceChangeState.showPopover)
  const showPopoverId = computed(() => state.priceChangeState.showPopoverId)
  const checkBeforeItem = computed(() => state.priceChangeState.checkBeforeItem)
  const checkAfterItem = computed(() => state.priceChangeState.checkAfterItem)
  const cartLeave = computed(() => state.cartLeave)
  const notCheckedItemsCouponSaved = computed(() => checkBeforeItem.value?.aggregateProductBusiness.estimatedPricePopUp?.subInfo?.items?.find(item => item.benefitType === 'coupon')?.data?.rightValuePrice)
  const {
    sessionClosePopover,
    initPriceChangeShowPopoverCount,
    initPriceChangeClosePopover,
    initPriceChangeSessionClosePopover,
    updatePriceChangeSessionClosePopover
  } = usePriceChangeCache()

  const updatePriceChangeState = (payload) => commit('updatePriceChangeState', payload)

  const initCartPriceChaneState = () => {
    onMountedOrActivated(() => {
      initPriceChangeShowPopoverCount()
      initPriceChangeClosePopover()
      initPriceChangeSessionClosePopover() 
    })
  }

  const resetCartPriceChangeState = () => {
    // 离开购物车或者气泡展示中重置为 false，否则保持原值
    const resetSessionClosePopoverState = (cartLeave.value || showPopover.value) ? false : sessionClosePopover.value
    updatePriceChangeState({ showPopover: false })
    updatePriceChangeSessionClosePopover(resetSessionClosePopoverState)
  }

  return {
    showPopoverMax,
    showPopover,
    showPopoverId,
    checkBeforeItem,
    checkAfterItem,
    notCheckedItemsCouponSaved,
    initCartPriceChaneState,
    resetCartPriceChangeState,
    updatePriceChangeState,
  }
}