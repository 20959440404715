<template>
  <div
    v-if="showPromotion"
    v-expose="analysisData('expose_cartpromotionadd.page_cart')"
    class="j-full-gift-header full-type-group header-platform"
    :class="{
      'j-top-full-gift': isSingleMall && !showAdd && !isPicked,
      'newcomer-guide__fullgifts': showFullGiftsList
    }"
  >
    <div
      class="cart_item-header item-header__full-gift ele-wrapper"
    >
      <section
        class="cart_item-banner"
        tabindex="0"
        :class="{'no-border': showCondition == 'picked' || showFullGiftsList, 'is-single-mall': isSingleMall}"
      >
        <div class="title-action-operation">
          <i
            class="promotion-item-icon item-content-icon"
          >
            <sui_icon_gift_18px size="18px" />
          </i>
          <div
            class="promotion-tip-time"
            :class="{'is-single-mall': isSingleMall}"
          >
            <!-- 提示 -->
            <p
              class="cart_item-desc"
              tabindex="0"
              v-html="promotionTip"
            >
            </p>
            <!-- 倒计时 -->
            <div
              v-if="promotionInfo.data.is_count_down && promotionInfo.data.end_time"
              class="client-only-height down-time"
            >
              <ClientOnly>
                <count-down-time :time-stamp="+promotionInfo.data.end_time" />
              </ClientOnly>
            </div>
          </div>
          <!-- 点击按钮 -->
          <div
            
            class="cart_item-action"
            tabindex="0"
            role="button"
            @click="handleFullGift"
          >
            <span>{{ actionText }}</span>
            <sui_icon_more_right_12px
              size="12px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </div>
      </section>
    </div>
    <!--   吸顶元素高度占位     -->
    <div class="j-top-act-height"></div>
    <FullGiftList
      v-if="showFullGiftsList"
      :gift-list="giftList"
      :promotion-id="promotionInfo.data.promotion_id"
      :disabled-tip="showAdd ? promotionTip : ''"
      :promotion-type="promotionInfo.data.type_id"
      :is-top="true"
    />
  </div>
</template>
<script>
export default {
  name: 'FullGiftHeader',
}
</script>
<script setup>
import { computed } from 'vue'
import { sui_icon_gift_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'
import CountDownTime from './../common/CartCountDownTime.vue'
import { useStore } from '../../hooks/useStore'
import ClientOnly from 'vue-client-only'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import FullGiftList from 'public/src/pages/cart_v2/components/cartList/FullGiftList.vue'

const { state, commit } = useStore()
const AddItem = useCartAddItem()
const props = defineProps({
  isSingleMall: {     // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    type: Boolean,
    default: true
  },
  promotionInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: ''
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  showCondition: {
    type: String,
    default: '' // 单mall：notPick picked
  },
})

const promotionTip = computed(() => {
  return props.promotionInfo?.promotionTips?.html_text || ''
})

const showPromotion = computed(() => {
  return props.showCondition ?  (props.showCondition == 'picked' && isPicked.value) : true
})
const showAdd = computed(() => {
  return props.promotionInfo && props.promotionInfo.data?.isMeet != 1
})

const isPicked = computed(() => {
  return props.promotionInfo && props.promotionInfo.data?.isPicked == 1
})

const actionText = computed(() => {
  return showAdd.value ? props.language.SHEIN_KEY_PWA_15091 : isPicked.value ? props.language.SHEIN_KEY_PWA_15831 : props.language.SHEIN_KEY_PWA_15830
})

const giftList = computed(() => {
  return props.promotionInfo.data?.promotionGoods || []
})

const showFullGiftsList = computed(() => {
  return !isPicked.value && giftList.value.length
})

const isSheinClubPromotion = computed(() => {
  return [8, 14].includes(+props.promotionInfo.data?.promotion_logo_type)
})

const handleFullGift = () => {
  const $topFullGift = document.querySelector('.j-top-full-gift .ele-wrapper')

  if($topFullGift?.classList?.contains('sticky-block') && showFullGiftsList.value){
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return
  }
  const promotionId = props.promotionInfo.data?.promotion_id
  if(!showAdd.value) {
    commit('changeAppendageDrawerStatus', { promotionId })
  } else {
    AddItem.show({
      popupInfoId: props.promotionInfo.data?.popupInfoId,
      state: 'promotion_add',
    })
  }
  daEventCenter.triggerNotice(analysisData('click_cartpromotionadd.page_cart'))
}

const analysisData = (id) => {
  let { promotion_id } = props.promotionInfo.data
  const { isFullPromotion, typeId } = state.promotionState.cartPromotionAnalysis?.[promotion_id] || {}
  return {
    id,
    data: {
      id: promotion_id,
      typeId: typeId,
      isFullPromotion: isFullPromotion,
      is_member_gift: +isSheinClubPromotion.value,
    }
  }
}
</script>
<style lang="less">
.item-header__full-gift{
  padding: 32/75rem 24/75rem 0;
  margin: 20/75rem 0 0;
  &.sticky-block{
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
  }
}
</style>