<template>
  <div
    class="header-platform"
  >
    <ClientOnly>
      <swiper-container
        :class="['cart-full-promotion', 'j-top-promotion-swiper-'+ mallCode]"
        init="false"
        direction="vertical"
        autoplay-delay="4000"
        autoplay-disable-on-interaction="true"
        observer="true"
        :dir="locals.GB_cssRight ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(item, index) in fullCourtMap"
          :key="'promotion-item-' + index"
          
          tabindex="0"
          role="button"
          @click="handlePromotionItemClick(item,index)"
        >
          <div
            v-expose="analysisData('expose_cartpromotionadd.page_cart', item.data)"
            class="cart-full-promotion__item"
          >
            <div class="cart-full-promotion__item-content">
              <Icon
                :name="getIconName(item)"
                :is-responsive-name="true"
                class="item-content-icon"
              />
              <div
                class="item-content-tip"
              >
                <div
                  :ref="'promotionTip'+index"
                  class="item-content-tip__txt"
                  v-html="item.promotionTips && item.promotionTips.html_text || ''"
                ></div>
                <div
                  v-if="processCount(item) < 100 && item.data && item.data.type_id == 21"
                  class="item-content-tip__process"
                >
                  <div
                    class="item-content-tip__process-bg"
                    :style="{ width: `${processCount(item)}%` }"
                  ></div>
                </div>
                <div
                  v-if="item.data.is_count_down && item.data.end_time"
                  class="client-only-height"
                >
                  <count-down-time :time-stamp="+item.data.end_time" />
                </div>
              </div>
              <div
                v-if="showMore"
                
                class="cart_item-action"
                tabindex="0"
                role="button"
                @click.stop="handlePromotionItemActionClick(item)"
              >
                <sui_icon_more_20px size="20px" />
              </div>
              <div
                v-else-if="item.data.isMeet != 1"
                
                class="cart_item-action"
                tabindex="0"
                role="button"
                @click.stop="handlePromotionItemActionClick(item)"
              >
                <span>{{ language.SHEIN_KEY_PWA_15091 }}</span>
                <sui_icon_more_right_12px
                  size="12px"
                  :is-rotate="locals.GB_cssRight"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </ClientOnly>
    <ClientOnly>
      <s-drawer
        :visible.sync="showDrawer"
        animate-type="small"
        @close="closeDrawer"
      >
        <template slot="top">
          <span>
            {{ promotionTitle }}
          </span>
        </template>
        <ul class="full-court__drawer-list">
          <li
            v-for="(item, index) in fullCourtDrawerMap"
            :key="'promotion-drawer-' + index"
            class="cart-top__promotionDrawer-item"
            tabindex="0"
            role="button"
            @click="handlePromotionDrawerItemClick(item)"
          >
            <div class="cart-top__promotionDrawer-item-content">
              <span class="item-tips__wrapper">
                <i>
                  <sui_icon_selected_16px
                    v-if="item.data.type_id == 21 && processCount(item) >= 100"
                    size="16px"
                  />
                </i>
                <span v-html="item.promotionTips && item.promotionTips.html_text || ''"></span>
              </span>
              <div
                v-if="processCount(item) < 100 && item.data.type_id == 21"
                class="item-content-tip__process"
              >
                <div
                  class="item-content-tip__process-bg"
                  :style="{ width: `${processCount(item)}%` }"
                ></div>
              </div>
              <ClientOnly>
                <count-down-time
                  v-if="item.data.is_count_down && item.data.end_time"
                  :time-stamp="+item.data.end_time"
                />
              </ClientOnly>
            </div>
            <div
              v-if="item.data.isMeet != 1"
              
              class="promotionDrawer-item-action"
              tabindex="0"
              role="button"
            >
              <span>{{ language.SHEIN_KEY_PWA_15091 }}</span>
              <sui_icon_more_right_12px
                size="12px"
                :is-rotate="locals.GB_cssRight"
              />
            </div>
            <i
              v-else
            ></i>
          </li>
        </ul>
      </s-drawer>
    </ClientOnly>
  </div>
</template>
<script>
// 促销全场活动：满折、满减、返券
export default {
  name: 'MallFullCourtMap',
}
</script>
<script setup>
import { ref, watch, nextTick, onMounted } from 'vue'
import ClientOnly from 'vue-client-only'
import { Autoplay } from 'swiper/modules'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { Icon, sui_icon_more_right_12px, sui_icon_more_20px, sui_icon_selected_16px } from '@shein-aidc/icon-vue2'
import CountDownTime from './../common/CartCountDownTime.vue'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import { register } from 'swiper/element'
if (typeof window !== 'undefined') {
  register()
}
function arrStep(arr, step) {
  if (step >= arr.length) return step
  const arrClone = [].concat(arr)
  const stepItems = arrClone.splice(0, step)
  return arrClone.concat(stepItems)
}

const AddItem = useCartAddItem()
const props = defineProps({
  fullCourtMap: {
    type: Array,
    default: () => ([])
  },
  language: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: ''
  },
  locals: {
    type: Object,
    default: () => ({})
  }
})

let showMore = ref(false)
let showDrawer = ref(false)
let fullCourtDrawerMap = ref([])
let promotionTitle = ref('')
let swiperEl = ref(null)
let promotionTip0 = ref(null)

onMounted(() => {
  nextTick(() =>handlePromotionItem(props.fullCourtMap.length))
})

watch(() => props.fullCourtMap.length, (n) => {
  handlePromotionItem(n)
})

const processCount = (item) => {
  return +(item.data?.promotionPopupInfo?.progressPercent) * 100
}

const getIconName = (item) => {
  if (item.data.type_id != 21) {
    return 'sui_icon_activity_18px'
  }

  if (processCount(item) >= 100 || item.data.diffMoney?.usdAmount == 0 || item.data.range > 0) {
    return 'sui_icon_selected_16px'
  }

  if (item.coupon_num > 1) {
    return 'sui_icon_couponbundle_16px'
  }

  return 'sui_icon_coupon_discount_18px'
}

const handlePromotionItem = (n) => {
  if(typeof window == 'undefined') {
    return
  }
  if (n && n > 1) {
    showMore.value = true
    nextTick(() => {
      if (typeof window != 'undefined') {
        initSwiper()
      }
    })
  } else if(n && n == 1){
    nextTick(()=>{
      const e = promotionTip0.value || {}
      if(e.scrollHeight > e.clientHeight) {
        showMore.value = true
      } else {
        showMore.value = false
      }
    })
  }
}

const initSwiper = () => {
  if (!swiperEl.value) swiperEl.value = document.querySelector('.j-top-promotion-swiper-' + props.mallCode)
  Object.assign(swiperEl.value, {
    modules: [Autoplay],
  })
  swiperEl.value.initialize()
  resizeSwiper()
}

const resizeSwiper = () => {
  let maxSliderHeight = 0
  let ele = '.cart-full-promotion__item'
  nextTick(() => {
    const slidersContent = document.querySelectorAll(`.j-top-promotion-swiper-${props.mallCode} ${ele}`)
    slidersContent.forEach((item) => {
      let computedStyle = window.getComputedStyle(item)
      const itemHeight = item.querySelector(`${ele}-content`)?.getBoundingClientRect().height || 0
      const itemPaddingTop = parseInt(computedStyle.getPropertyValue('padding-top'), 10)
      const itemPaddingBottom = parseInt(computedStyle.getPropertyValue('padding-bottom'), 10)
      const computedHeight = itemPaddingTop + itemPaddingBottom + itemHeight
      if (computedHeight > maxSliderHeight)
        maxSliderHeight = computedHeight
    })
    let swiperContainerPromotionDom = document.querySelector(`.j-top-promotion-swiper-${props.mallCode}`)
    if(swiperContainerPromotionDom) {
      swiperContainerPromotionDom.style.height = `${maxSliderHeight}px`
    }
  })
}

const closeDrawer = () => {
  showDrawer.value = false
  swiperEl.value?.swiper?.autoplay?.start()
}

const openDrawer = (item) => {
  showDrawer.value = true
  if (swiperEl.value?.swiper) {
    fullCourtDrawerMap.value = arrStep(
      props.fullCourtMap,
      swiperEl.value.swiper.activeIndex
    )
    swiperEl.value.swiper.autoplay?.stop()
  } else {
    fullCourtDrawerMap.value = []
    fullCourtDrawerMap.value.push(item)
  }
}

const handlePromotionItemClick = (item, index) => {
  promotionTitle.value = item.data.type_id == 21 ? props.language.SHEIN_KEY_PWA_20407 : props.language.SHEIN_KEY_PWA_20406
  if(props.fullCourtMap.length > 1) {
    promotionTitle.value = props.language.SHEIN_KEY_PWA_17051
  }

  if (props.fullCourtMap.length > 1 || showMore.value) return openDrawer(item)

  if (item.data.isMeet != 1) {
    daEventCenter.triggerNotice(analysisData('click_cartpromotionadd.page_cart', item.data))
    AddItem.show({
      popupInfoId: item.data?.popupInfoId,
      state: 'promotion_add',
    })
  }
}

const handlePromotionItemActionClick = (item) => {
  handlePromotionItemClick(item)
}

const analysisData = (id, data) => {
  let { promotion_id, type_id, isMeet } = data
  if(isMeet == 1) {
    return {}
  }
  return {
    id,
    data: {
      id: promotion_id,
      typeId: type_id,
      isFullPromotion: 1,
      is_member_gift: 0,
    }
  }
}
</script>
<style lang="less">

/* CartTopPromotionDrawer 顶部促销提示共融抽屉弹窗
----------------------------------------------------------------*/
.full-court__drawer-list{
  padding: 0 24/75rem 96/75rem;
}
.cart-top__promotionDrawer-item {
  position: relative;
  padding: 32/75rem 0;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .promotionDrawer-item-action{
    font-size: 12px;
    white-space: nowrap;
    font-weight: bold;
  }
  .cart-top__promotionDrawer-item-content {
    color: #222222;
    .margin-r(.32rem);
    em {
      color: @sui_color_welfare_dark;
    }
    .item-tips__wrapper {
      display: flex;
      align-items: center;
      i {
        .margin-r(.2133rem);
      }
    }
  }
  .count-down-time{
    margin-top: 20/75rem;
  }
}

/* 新的全场活动样式 */
.cart-full-promotion{
  display: block;
  margin: 0;
  // max-height: 156/75rem;
  background-color: @sui_color_promo_bg;
  &__item{
    padding: 20/75rem;
    height: auto !important; /* stylelint-disable-line declaration-no-important */
    background-color: @sui_color_promo_bg;
  }
  &__item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content-icon{
      font-size: 16px;
      margin-right: 20/75rem;
      width: 32/75rem;
      height: 32/75rem;
      line-height: 1;
    }
    .item-content-tip{
      padding-right: 20/75rem;
      flex: 1;
      .item-content-tip__txt{
        color: #222;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .item-content-tip__txt{
    em{
      color: @sui_color_welfare_dark;
    }
  }
}
.item-content-tip__process {
  width: 100%;
  height: .08rem;
  background-color: @sui_color_gray_light2;
  border-radius: 2px;
  margin: .1067rem 0;
}
.item-content-tip__process-bg {
  height: .08rem;
  background-color: @sui_color_main;
  border-radius: 2px;
  transition: width .5s linear;
}
</style>
