<template>
  <div class="cart-promotion-bottom">
    <component 
      :is="bottomCompName"
      :list="swipeSortList"
      :showWay="showWay"
      @openHub="openHub"
    />

    <LazyMount>
      <s-drawer
        :visible.sync="showPromotionHubDrawer"
        :append-to-body="true"
        class="promos-hub"
        :class="{
          'promos-hub_activity': isShowCartActivityColor
        }"
      >
        <template slot="top">
          <div class="promos-hub_title">
            {{ language.SHEIN_KEY_PWA_24802 }}
          </div>
        </template>
        <div class="promos-hub_content">
          <component
            :is="getCompName(item)"
            v-for="(item, index) in bottomPromotions"
            :key="`${item.sortAbt}_${index}`"
            class="promos-hub_item"
            :promoInfo="item"
            :isInModal="true"
            :showWay="showWay"
          />
        </div>
      </s-drawer>
    </LazyMount>
  </div>
</template>
<script>
export default {
  name: 'CartPromotionBottom',
}
</script>
<script setup>
import { computed, provide, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import BottomCommon from './components/BottomCommon.vue'
import BottomSwipe from './components/BottomSwipe.vue'
import PromoBar from './components/PromoBar.vue'
import FreeShipBar from './components/FreeShipBar.vue'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import { LazyMount } from '@shein/sui-mobile'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const { state, getters, commit } = useStore()
const AddItem = useCartAddItem()
const { registerModalState, updateModalState } = useUpdateModalState()


const bottomPromotionConfig = computed(() => state.promotionState.bottomPromotionConfig)
const language = computed(() => state.language)
const bottomPromotions = computed(() => getters['bottomPromotions'])
const isShowCartActivityColor = computed(() => getters['isShowCartActivityColor'])
const bottomCompName = computed(() => {
  if (bottomPromotions.value?.length === 1) return BottomSwipe
  if (bottomPromotionConfig.value?.style == '2') return BottomCommon
  return BottomSwipe
})
const showWay = computed(() => {
  if (bottomPromotions.value?.length === 1) return 'priority'
  if (bottomPromotionConfig.value?.style == '2') return 'general'
  return 'roll'
})
const swipeSortList = computed(() => {
  return [...bottomPromotions.value].sort((a, b) => a.sortAbt - b.sortAbt)
})

const showPromotionHubDrawer = computed({
  get: () => state.promotionState.promoHubOldVisible,
  set: (val) => {
    commit('updatePromotionState', { promoHubOldVisible: val })
  }
})

watch(() => swipeSortList.value, (val) => {
  if (val?.length >= 2) {
    daEventCenter.triggerNotice({
      daId: '1-8-5-29',
      extraData: {
        show_way: showWay.value,
        count: val?.length,
      }
    })
  }
}, { immediate: true })

watch(() => showPromotionHubDrawer.value, (val) => {
  if (val) {
    registerModalState()
  } else {
    updateModalState()
  }
})

const getCompName = (item) => {
  return item?.isFreeShipping ? FreeShipBar : PromoBar
}
// Methods
const openHub = () => {
  commit('updatePromotionState', { promoHubOldVisible: true })
}

const handleAddOn = (payload) => {
  let options = {}
  if (payload?.type_id == '10000') {
    options = {
      coupon: payload?.addItemParams?.couponCode,
      state: 'coupon_add',
    }
  } else if (payload?.isFreeShipping) {
    options = payload?.type === 'coupon' ? {
      coupon: payload?.coupon,
      state: 'coupon_helper_add',
    } : {
      popupInfoId: payload?.popupInfoId,
      state: 'shipping_add',
    }
  } else {
    options = {
      popupInfoId: payload?.popupInfoId || payload?.promotion_id,
      state: 'promotion_add',
    }
  }

  AddItem.show(options)
}
const handlePickItem = (pid) => {
  commit('changeAppendageDrawerStatus', { promotionId: pid })
}

provide('handleAddOn', handleAddOn)
provide('handlePickItem', handlePickItem)
</script>
<style lang="less" scoped>
.cart-promotion-bottom {
  width: 100%;
}

.promos-hub {
  /deep/ .promos-hub_title {
    color: @sui_color_gray_light1;
    font-size: 14px;
  }
  /deep/ .promos-hub_content {
    padding: 0.27rem .32rem .64rem;
  }
  /deep/ .promos-hub_item {
    margin-bottom: 0.16rem !important;/* stylelint-disable-line declaration-no-important */
    overflow: hidden;
  }
  &.promos-hub_activity {
   /deep/ .S-drawer__container {
      border-radius: 12/37.5rem 12/37.5rem 0 0;
      background: linear-gradient(to bottom, #FFD9CD 0%, #FFF 8.56%), #FFF;
      /* stylelint-disable-next-line selector-max-specificity */
      .S-drawer__header, .S-drawer__body {
        background-color: transparent;
      }
   } 
  }
}
</style>
