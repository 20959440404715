import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { useRouter } from 'public/src/pages/cart_v2/hooks/useRouter'
import { useNetworkStatus } from 'public/src/pages/cart_v2/components/offline/useNetworkStatus.js'
import { click_promotion_block, expose_promotion_block, expose_cartpromotionadd, expose_flash_link, expose_live_flashsale, expose_insured_goods, click_cartpromotionadd, click_membergift_tag, click_flash_link, click_live_flashsale } from 'public/src/pages/cart_v2/analysis/item/promotionTag.js'
import { markPoint } from 'public/src/services/mark/index.js'

export function usePromotionTagEvent() {
  const { state } = useStore()
  const batchActive = computed(() => state.batchActive)

  const { routerPush } = useRouter()

  const onClick = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {
    if (batchActive.value) return
    const { isOnline } = useNetworkStatus()
    let { flashType, promotionId, typeId, jumpLink = '' } = event?.data?.[event?.type] || {}
    const src_tab_page_id = typeof window != 'undefined' ? window.getSaPageInfo?.tab_page_id || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '') : ''
    if (typeId != '30') {
      click_cartpromotionadd(data.value, (defaultData) => {
        const data = {
          ...defaultData,
          typeId,
          activity_id: promotionId,
        }
        if (analysis?.click_cartpromotionadd) {
          return analysis.click_cartpromotionadd(data)
        }
        return data
      })
    }
    if (event?.type == 'payMemberGift') {
      click_membergift_tag(data.value, analysis?.click_membergift_tag)
    }
    if (!isOnline.value) return

    // 品牌特卖
    if (typeId == '10' && flashType == '6') {
      jumpLink = jumpLink.replace('{ps}', index).replace('{src_tab_page_id}', src_tab_page_id)
    }
    // 一口价
    if (typeId == '30') {
      jumpLink = jumpLink.replace('{ici}', 'PageCart').replace('{src_tab_page_id}', src_tab_page_id).replace('{ps}', index)
    }
    if(jumpLink){
      markPoint('toNextPageClick', 'public')
      routerPush(gbCommonInfo.langPath + jumpLink)
    }
  }

  const onReport = ({ itemOpts: { data, event },  options: { index = 1 } = {}, analysis = {} }) => {
    if (batchActive.value) return
    const { promotionId, ruleNum = '', typeId, flashType = '0' } = event?.data?.[event?.type] || {}
    const content_list = `underPrice_${ruleNum}`
    const src_tab_page_id = typeof window != 'undefined' ? window.getSaPageInfo?.tab_page_id || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '') : ''
    if (!['32'].includes(typeId)) {
      if (['30'].includes(typeId) || (typeId == '10' && flashType === '6')) {
        click_promotion_block(data.value, (defaultData) => {
          const data = {
            ...defaultData,
            promotion_id: promotionId,
            promotion_tp_id: typeId,
            content_list,
            src_module: 'promotion',
            src_identifier: `on=${typeId}\`cn=${promotionId}\`ps=${index}_1\`jc=${content_list}`,
            src_tab_page_id,
          }
          if (analysis?.click_promotion_block) {
            return analysis.click_promotion_block(data)
          }
          return data
        })
      }
      if (['10'].includes(typeId)) {
        click_flash_link(data.value, (defaultData) => {
          defaultData.flashTypes[0] = flashType
          if (analysis?.click_flash_link) {
            return analysis.click_flash_link(defaultData)
          }
          return defaultData
        })
      }
      if (['11'].includes(typeId)) {
        click_live_flashsale(data.value)
      }
    }
  }

  const onExpose = ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {
    const { promotionId, ruleNum = '', typeId, flashType = '0' } = event?.data?.[event?.type] || {}
    const content_list = `underPrice_${ruleNum}`
    const src_tab_page_id = typeof window != 'undefined' ? window.getSaPageInfo?.tab_page_id || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '') : ''
    if (!['32'].includes(typeId)) {
      if (['30'].includes(typeId) || (typeId == '10' && flashType === '6')) {
        expose_promotion_block(data.value, (defaultData) => {
          const data = {
            ...defaultData,
            promotion_id: promotionId,
            promotion_tp_id: typeId,
            content_list,
            src_module: 'promotion',
            src_identifier: `on=${typeId}\`cn=${promotionId}\`ps=${index}_1\`jc=${content_list}`,
            src_tab_page_id,
          }
          if (analysis?.expose_promotion_block) {
            return analysis.expose_promotion_block(data)
          }
          return data
        })
      }
      if (['3', '10'].includes(typeId)) {
        expose_cartpromotionadd(data.value, (defaultData) => {
          const data = {
            ...defaultData,
            typeId,
            isFullPromotion: 0,
            activity_id: promotionId,
          }
          if (analysis?.expose_cartpromotionadd) {
            return analysis.expose_cartpromotionadd(data)
          }
          return data
        })
      }
      if (['10'].includes(typeId)) {
        expose_flash_link(data.value, (defaultData) => {
          defaultData.flashTypes[0] = flashType
          if (analysis?.expose_flash_link) {
            return analysis.expose_flash_link(defaultData)
          }
          return defaultData
        })
      }
      if (['11'].includes(typeId)) {
        expose_live_flashsale(data.value)
      }
      if (event?.type == 'insurePrice') {
        expose_insured_goods(data.value)
      }
    }
    if (['32'].includes(typeId)) {
      expose_cartpromotionadd(data.value, (defaultData) => {
        const data = {
          ...defaultData,
          typeId,
          isFullPromotion: 0,
          activity_id: promotionId,
        }
        if (analysis?.expose_cartpromotionadd) {
          return analysis.expose_cartpromotionadd(data)
        }
        return data
      })
    }
  }

  return {
    onClick,
    onReport,
    onExpose,
  }
}
