import { extractQueryString, parseQueryString } from '@shein/common-function'
import { click_ranking_list_label } from 'public/src/pages/cart_v2/analysis/item/rankListTag.js'
import { openRank } from 'public/src/pages/common/ranking_list/rankTool.js'
import { useRouter } from 'public/src/pages/cart_v2/hooks/useRouter'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'

export function useRankLiskTagEvent() {
  const { routerPush } = useRouter()
  const { dispatch } = useStore()
  const onClick = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {
    const tag = data.value?.aggregateProductBusiness?.productTags?.find(item => item.type === 'rankList')
    const query = parseQueryString(extractQueryString(tag?.data?.rankList?.jumpLink))
    const params = JSON.parse(query?.data)
    const rankTab = openRank({
      type: params.is_pop_up > 0 ? 'drawer': 'url',
      params: {
        contentCarrierId: params.content_carrier_id,
        carrierSubType: params.carrier_sub_type,
        goods_id: params.goods_id,
        src_identifier: data.value?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.rankListIdentifier,
        src_module: 'infoflow',
        src_tab_page_id: typeof window != 'undefined' ? window.getSaPageInfo?.tab_page_id || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '') : ''
      },
      config: {
        hideCallback: () => {
          dispatch('fetchCartIndex')
        }
      }
    })
    if(params.is_pop_up <= 0) { // url需要手动跳转, 弹窗类型会自动打开
      routerPush(rankTab)
    }
  }

  const onReport = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {
    click_ranking_list_label(data.value, (defaultData) => {
      const data = {
        ...defaultData,
      }
      if(analysis?.click_ranking_list_label){
        return analysis.click_ranking_list_label(data)
      }
      return data
    })
  }

  const onExpose = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {

  }

  return {
    onClick,
    onReport,
    onExpose,
  }
}