import { computed } from 'vue'
import { useStore } from './useStore'
import useChecked from './useChecked'
import useLoadingWithDelay from './useLoadingWithDelay'
import { web_modify_check_click, web_modify_check_response } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { Toast } from '@shein/sui-mobile'
import { click_select_someproduct } from 'public/src/pages/cart_v2/analysis/checkbox'

export default function useModifyCartCheckStatus() {
  const { setLocalChecked } = useChecked()
  const { state, dispatch, commit } = useStore()
  const cartItemMap = computed(() => state.cartItemMap)
  const language = computed(() => state.language)
  const selectFilterLabel = computed(() => state.selectFilterLabel)
  const monitorUserInteraction = computed(() => state?.monitorUserInteraction)
  const carts = computed(() => state.carts)

  let lock = false

  /**
   * 修改勾选状态
   * @param {object} options
   * @param {array} [options.cartIdList] 商品行id列表，不是全选/全不选时必传
   * @param {number} options.operationType 操作类型，1-勾选，2-取消勾选，3-全选，4-全不选
   * @param {number} options.type 操作的范围，item-单个商品行，all-全部商品行 section-部分商品行
   * @param {function} [options.onBeforeModify] 修改前的回调
   * @param {function} [options.onAfterModify] 修改后的回调
   * @param {object} [analysis] 埋点数据回调
   */
  const modifyCartCheckStatus = async ({ cartIdList, operationType, type = 'item', onBeforeModify, onAfterModify, isShowOverLimitToast = false }, analysis) => {
    if (lock) return
    lock = true
    let localCheckedIds
    let localChecked
    try {
      web_modify_check_click([2, 4].includes(+operationType) ? 0 : 1, type)
      if (type == 'item') {
        const target = cartItemMap.value?.[cartIdList?.[0]] || {}
        if((parseInt(target.quantity) > parseInt(target.realTimeInventory))){
          Toast(language.value.SHEIN_KEY_PWA_20624)
          return
        }
        click_select_someproduct(target, (defaultData) => {
          const data = { ...defaultData, label_id: selectFilterLabel.value?.labelId }
          if (analysis?.click_select_someproduct) {
            return analysis?.click_select_someproduct(data)
          }
          return data
        })
      }
      const reqParams = {
        operation_type: operationType,
      }
      if ([1, 2].includes(+operationType)) {
        localCheckedIds = cartIdList
        localChecked = operationType == 1
        reqParams.cart_id_list = cartIdList
        const carts = cartIdList.map(cartId => cartItemMap.value[cartId])
        const append_id_list = Array.from(new Set(carts.reduce((acc, cur) => {
          return acc.concat(cur.appendIds || [])
        }, [])))
        reqParams.append_id_list = append_id_list
      } else if ([3, 4].includes(+operationType)) {
        localCheckedIds = carts.value?.map(item => item.id).filter(item => item.isInvalid != '1')
        localChecked = operationType == 3
      }
      setLocalChecked({ ids: localCheckedIds, checked: localChecked })
      onBeforeModify && onBeforeModify()
      const { doRequest } = useLoadingWithDelay(
        () => dispatch('fetchModifyCartCheckStatus', reqParams),
        (show) => {
          commit('changeRootSLoadingStatus', {
            show,
            maskTransparent: true,
            type: 'curpage',
          })
        }
      )
      const res = await doRequest()
      // 当更新商品选中状态时，停止低效用户监测
      monitorUserInteraction.value?.stop?.({ reason: 'update check status' })
      web_modify_check_response([2, 4].includes(+operationType) ? 1 : 0, type, res?.code == 0 ? 1 : 0)
      if (res.code != '0') {
        setLocalChecked({ ids: localCheckedIds, checked: undefined })
        res.msg && (Toast(res.msg))
      }
      if (res.code == '0') {
        dispatch('showOverLimitToast', { id: type === 'item' ? cartIdList[0] : '-1', isShowOverLimitToast })
        commit('updateGuideAddOnItem', { cartIds: cartIdList || '', type, operationType })
      }
      onAfterModify && onAfterModify({ res })
      return res
    } catch (error) {
      setLocalChecked({ ids: localCheckedIds, checked: undefined })
      onAfterModify && onAfterModify({ res: error })
      console.error(error)
    } finally {
      // eslint-disable-next-line require-atomic-updates
      lock = false
    }
  }

  return {
    modifyCartCheckStatus,
  }
}
