<template>
  <div
    v-show="showCountDown"
    class="cont-down"
  >
    <div
      v-if="type == 'white'"
      class="normal"
    >
      <div class="ltr-row">
        <span>{{ twoNums(diffHours) }}</span> :
        <span>{{ twoNums(diffMinutes) }}</span> :
        <span>{{ twoNums(diffSeconds) }}</span>
      </div>
    </div>
    <div
      v-else
      class="special"
      :class="type && `bg-${type}`"
    >
      <span>{{ endInText }}</span><span>{{ twoNums(diffHours) }}</span>:<span>{{ twoNums(diffMinutes) }}</span>:<span>{{ twoNums(diffSeconds) }}</span>
    </div>
  </div>
</template>
<script>
import { useTimeEndRefresh } from 'public/src/pages/cart_v2/hooks/useTimeEndRefresh.js'

export default {
  name: 'CountDown',
  props: {
    countDown: {
      type: [Object, Number],
      required: false,
      default: 0
    },
    timeStamp: {
      type: [Number, String],
      required: false,
      default: 0
    },
    isLeftTime: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      default: 'white',
    },
    language: {
      type: Object,
      default: () => ({}),
    }
  },
  data: function () {
    return {
      diffDays: '',
      diffHours: '',
      diffMinutes: '',
      diffSeconds: '',
      timer: null,
      showCountDown: true
    }
  },
  computed: {
    endInText () {
      if (this.language && this.language.SHEIN_KEY_PWA_16271) {
        return this.language.SHEIN_KEY_PWA_16271.replace('{0}', '')
      } else {
        return ''
      }
    }
  },
  setup () {
    const { refreshHanlder } = useTimeEndRefresh()
    return {
      refreshHanlder,
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    if (this.timer) clearInterval(this.timer)
  },
  methods: {
    init () {
      if( +this.timeStamp) {
        const countDownData = +this.timeStamp ? countDownTime({ endTimestamp: this.isLeftTime ? (Date.now() + (+this.timeStamp * 1000)) : +this.timeStamp }) : typeof this.countDown == 'object' ? this.countDown : countDownTime({ endTimestamp: this.countDown })
        this.diffDays = 0
        this.diffHours = +countDownData.diffHours + (+countDownData.diffDays * 24 )
        this.diffMinutes = +countDownData.diffMinutes
        this.diffSeconds = +countDownData.diffSeconds
        this.setTimer()
      } else {
        this.showCountDown = false
      }
    },
    setTimer () {
      if (this.timer) {
        return       
      } else {
        this.timer = setInterval(() => {
          if (this.diffSeconds - 1 >= 0) {
            this.diffSeconds = this.diffSeconds - 1
          } else {
            if (this.diffMinutes - 1 >= 0) {
              this.diffMinutes = this.diffMinutes - 1
              this.diffSeconds = 59
            } else {
              if (this.diffHours - 1 >= 0) {
                this.diffHours = this.diffHours - 1
                this.diffMinutes = 59
                this.diffSeconds = 59
              } else {
                if (this.diffDays - 1 >= 0) {
                  this.diffDays = this.diffDays - 1
                  this.diffHours = 24
                  this.diffMinutes = 59
                  this.diffSeconds = 59
                } else {
                  clearInterval(this.timer)
                  this.showCountDown = false
                  // window.location.reload(true)
                  this.refreshHanlder()
                }
              }
            }
          }
        }, 1000)
      }
    },
    twoNums (val) {
      if (typeof val === 'number' && !isNaN(val)) {
        return val < 10 ? `0${val}` : `${val}`
      } else {
        return ''
      }
    }
  },
}

/**
 * 计算倒计时
 */
function countDownTime ({ endTimestamp = 0 }) {
  let end = endTimestamp || 0
  if(end){
    end = (end + '').length >= 13 ? end : end * 1000
  }
  const now = (new Date()).getTime()
  const diffTime = end - now
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}
</script>
<style lang="less">
.cont-down {
  font-weight: normal;
  .font-dpr(24px);  
  .normal {
    .ltr-row {
      display: inline-block;
      /* rtl:begin:ignore */
      direction: ltr;
    }
    span{
     background: #fff;
     padding: 1/75rem 2/75rem;
   }
  }

  .special {
    padding: 0 8/75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &.bg-green {
      color: #fff;
      background: @sui_color_safety;
    }
    &.bg-orange {
      color: #fff;
      background: @sui_color_discount;
    }
    &.bg-black {
      color: @sui_color_white;
      background: @sui_color_gray_dark1;
    }
  }
}
</style>
