<template>
  <s-checkbox
    :value="checkAllItem"
    label="all"
    :disabled="isDisabledCheckedAll"
    @change="handleChange"
  >
    {{ language.SHEIN_KEY_PWA_15623 }}
  </s-checkbox>
</template>
<script>
/**
 * @description 购物车/结算BAR/全选小组件
 */
export default {
  name: 'CartCheckoutAll'
}
</script>
<script setup>
import { computed } from 'vue'
import { useStore } from '../../hooks/useStore'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import useChecked from 'public/src/pages/cart_v2/hooks/useChecked'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { usePopStatus, CART_INCENTIVE_PRODUCT_LIST, CART_ASSETS_BENEFIT_POP } from '../../hooks/usePopLoader'

const { isShow: isShowCartIncentiveProductList } = usePopStatus(CART_INCENTIVE_PRODUCT_LIST)
const { isShow: isShowCartAssetsBenefitPop } = usePopStatus(CART_ASSETS_BENEFIT_POP)

const { getChecked } = useChecked()
const { state } = useStore()

const carts = computed(() => state.carts)
const language = computed(() => state.language)
const promoHubVisible = computed(() => state.promotionState.promoHubVisible)
const showPromotionInterceptionV2 = computed(() => state.showPromotionInterceptionV2)
const showNewGoodsRecommendDrawer = computed(() => state.newGoodsRecommendParams.show)
const isShowCartFloorDrawer = computed(() => state.isShowCartFloorDrawer)
const selectFilterLabel = computed(() => state?.filterState?.selectFilterLabel)

const isDisabledCheckedAll = computed(() => {
  let normalItems = carts.value?.filter(item => item.isInvalid != '1') || [] // 购物车内所有告罄商品
  if(showNewGoodsRecommendDrawer.value || isShowCartIncentiveProductList.value || isShowCartAssetsBenefitPop.value || showPromotionInterceptionV2.value || promoHubVisible.value || isShowCartFloorDrawer.value || selectFilterLabel.value?.filterTagId !== 'all') return true
  return normalItems.length ? false : true
})
const checkAllItem = computed(() => {
  if(isDisabledCheckedAll.value) return false
  const ids = (carts.value?.filter(item => item.isInvalid != '1') || []).map(item => item.id)
  return getChecked(ids)
})

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const handleChange = () => {
  const onBeforeModify = () => {
    daEventCenter.triggerNotice({
      daId: '1-8-3-8',
      extraData: {
        is_select: checkAllItem.value ? 1 : 0,
      }
    })
  }
  modifyCartCheckStatus({ operationType: checkAllItem.value ? 4 : 3, type: 'all', onBeforeModify })
}
</script>
