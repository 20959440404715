<template>
  <div class="bottom-swipe">
    <transition 
      :css="animateHooks.css"
      v-on="animateHooks"
    >
      <keep-alive>
        <component 
          :is="compName"
          :key="currentItem.key"
          :promoInfo="currentItem"
          :floatCountDown="true"
          :showWay="showWay"
        >
          <template
            v-if="num > 1"
            #prefix
          >
            <PromoNum
              :num="num"
              @click.native.stop="openPromoHub"
            />
          </template>
        </component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import PromoNum from './PromoNum.vue'
import PromoBar from './PromoBar.vue'
import FreeShipBar from './FreeShipBar.vue'
import { animateHooks } from './utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const { GB_cssRight, CART_ACTIVITY_SWIPER_DURATION } = gbCommonInfo
const getDuration = () => {
  const d = Number(CART_ACTIVITY_SWIPER_DURATION)
  return isNaN(d) ? 5000 : d
}

export default {
  name: 'BottomSwipe',
  components: { PromoNum, PromoBar, FreeShipBar },
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    showWay: {
      type: String,
      default: '-',
    }
  },
  inject: { fixedUptt: { default: () => {} } },
  data() {
    return {
      GB_cssRight,

      animateHooks,
      currentIndex: 0,
      swipeList: [],
    }
  },
  computed: {
    num() {
      return this.list?.length || 0
    },
    currentItem() {
      return this.swipeList?.[this.currentIndex] || {}
    },
    compName() {
      return this.currentItem?.isFreeShipping ? 'FreeShipBar' : 'PromoBar'
    }
  },
  watch: {
    list: {
      handler (v) {
        if (v.length) {
          daEventCenter.triggerNotice({
            daId: '1-8-5-22',
            extraData: {
              show_way: this.showWay,
              count: this.num,
            }
          })

          this.swipeList = v.map((v, i) => ({ ...v, key: `${Date.now()}${i}` }))
          if (this.num > 1) {
            this.startTimer()
          }
        }
      },
      immediate: true,
    }
  },
  mounted() {
    this.fixedUptt?.()
  },
  activated() {
    this.fixedUptt?.()
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    startTimer() {
      this.clearTimer()
      this.timer = setInterval(() => {
        if (this.currentIndex === this.swipeList.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex++
        }
        this.fixedUptt?.()
      }, getDuration())
    },
    clearTimer() {
      this.currentIndex = 0
      clearInterval(this.timer)
    },
    openPromoHub() {
      daEventCenter.triggerNotice({
        daId: '1-8-5-23',
        extraData: {
          show_way: this.showWay,
          count: this.num,
        }
      })
      this.$emit('openHub')
    }
  }
}
</script>

<style lang="less" scoped>
.bottom-swipe {
  position: relative;
  bottom: -1px;
}
</style>
