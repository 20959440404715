<template>
  <div
    ref="CustomDrawer"
    class="custom-drawer"
    :style="styles"
  >
    <div 
      v-show="isShowMask"
      class="drawer-mask" 
      @click="handleClose"
    >
    </div>
    <Transition 
      name="slide"
      @after-enter="onAfterEnter"
      @after-leave="onAfterLeave"
    >
      <div 
        v-show="value"
        ref="drawerContent"
        class="drawer-content"
        :style="drawerContentStyle"
      >
        <slot :distanceToBottom="distanceToBottom"></slot>
        <Icon
          v-if="!hideCloseIcon"
          class="close-icon"
          :color="iconColor"
          name="sui_icon_close_16px"
          size="16px"
          @click="handleClose" 
        />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { debounce } from '@shein/common-function'
import { useRouterLevel } from 'public/src/pages/cart_v2/hooks/useRouterLevel.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const emit = defineEmits(['input', 'close', 'closed', 'afterEnter'])
const props = defineProps({
  value: { // 隐藏显示
    type: Boolean,
    default: false,
  },
  attach: { // 附属于某个dom，用于定位
    type: [String, Object],
    default: null,
  },
  iconColor: {
    type: String,
    default: '#222',
  },
  styleConfig: {
    type: Object,
    default: () => ({
      top: '10%',
      height: 'auto'
    }),
  },
  hideCloseIcon: {
    type: Boolean,
    default: false,
  },
})
const { state, commit } = useStore()
const { register, unregister } = useRouterLevel()
const currentY = ref(0)
const distanceToBottom = ref(0) // 默认0, 全屏
const contentHeight = ref('')
const key = ref(null)
const drawerContent = ref(null)

const customDrawerKeys = computed(() => state.customDrawerKeys)
const attachDom = computed(() => typeof props.attach === 'string' ? document.querySelector(props.attach) : props.attach)
const styles = computed(() => ({
  '--drawer-bottom': `${distanceToBottom.value}px`,
  '--drawer-height': props.styleConfig.height || contentHeight.value,
  '--drawer-max': props.styleConfig.max || '100%',
  '--drawer-min': props.styleConfig.min || '0%',
  '--drawer-z-index': props.styleConfig.zIndex || '',
}))
const drawerContentStyle = computed(() => ({
  'borderRadius': props.styleConfig.borderRadius || `${12 / 37.5}rem ${12 / 37.5}rem 0 0`,
}))
const isShowMask = computed(() => props.value && customDrawerKeys.value[0]?.key === key.value)

const updateCustomDrawers = (payload) => {
  commit('updateCustomDrawers', payload)
}
const init = () => {
  nextTick(() => {
    calcDistanceToBottom()
    initMutationObserver()
  })
}
const calcDistanceToBottom = () => {
  if (!attachDom.value) return
  const rect = attachDom.value.getBoundingClientRect()
  currentY.value = rect.y
  distanceToBottom.value = (window?.innerHeight - rect.y) || 0
}   
const calcHeight = () => {
  contentHeight.value = drawerContent.value.clientHeight + distanceToBottom.value + 'px'
}
const initMutationObserver = () => {
  if (!attachDom.value) return
  const debounceFn = debounce({
    func: () => {
      calcDistanceToBottom()
    },
    wait: 17,
  })

  const observer = new MutationObserver(() => {
    const rect = attachDom.value.getBoundingClientRect()
    if (currentY.value !== rect.y) {
      debounceFn()
    }
  })
  observer.observe(attachDom.value, { 
    attributes: true, 
    attributeFilter: ['style', 'class'],
    childList: true,
    subtree: true,
  })
}   
const handleClose = () => {
  emit('input', false)
  emit('close')
}
const onAfterEnter = () => {
  emit('afterEnter')
}
const onAfterLeave = () => {
  emit('closed')
}
const toggleUptt = (show) => {
  if (typeof window === 'undefined' || !document.querySelector('.j-uptt')) return
  document.querySelector('.j-uptt').style.visibility = show ? 'visible' : 'hidden'
}   

onMounted(() => {
  init()
})

watch(() => props.value, (n) => {
  if (n) {
    nextTick(() => {
      register(handleClose)
      document.body.classList.add('cart-hidden-to-body')
      calcDistanceToBottom()
      if (n && !props.styleConfig.height) {
        calcHeight()
      }
      key.value = Symbol()
      updateCustomDrawers({ key: key.value, action: 'add', close: handleClose })
      toggleUptt(false)
    })
  } else {
    unregister()
    updateCustomDrawers({ key: key.value, action: 'remove' })
    if (customDrawerKeys.value.length === 0) {
      document.body.classList.remove('cart-hidden-to-body')
      toggleUptt(true)
    }
  }
}, {
  immediate: true
})
</script>
<style lang="less" scoped>
.custom-drawer {
  position: fixed;
  z-index: var(--drawer-z-index);
  .drawer-mask {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: var(--drawer-bottom);
    background: rgba(0,0,0,0.4);
  }
  .drawer-content {
    position: fixed;
    width: 100%;
    max-width: 10rem;
    bottom: var(--drawer-bottom);
    height: calc(var(--drawer-height) - var(--drawer-bottom));
    min-height: calc(var(--drawer-min) - var(--drawer-bottom));
    max-height: calc(var(--drawer-max) - var(--drawer-bottom));
    background: #fff;
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    overflow: hidden;
    z-index: 9;
    .close-icon {
      position: absolute;
      width: 26/37.5rem;
      height: 26/37.5rem;
      right: 0;
      top: 0;
      padding: 4/37.5rem 4/37.5rem 0 0;
    }
  }
}

.slide-enter,
.slide-leave-active {
  transform: translate3d(0, 100%, 0);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter-to,
.slide-leave {
  transform: translate3d(0, 0, 0);
}
</style>
