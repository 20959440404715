import { computed } from 'vue'
import { useStore } from './useStore'

export default function useChecked() {
  const { state, commit } = useStore()
  const localChecked = computed(() => state.localChecked)
  const cartItemMap = computed(() => state.cartItemMap)

  const setLocalChecked = ({ ids, checked }) => {
    // 兼容数组和单个传参
    ids = Array.isArray(ids) ? ids : [ids]
    commit('updateLocalChecked', { ids, checked })
  }

  const getChecked = (ids = []) => {
    // 兼容数组和单个传参
    ids = Array.isArray(ids) ? ids : [ids]
    return ids.every(id => {
      if (localChecked.value?.[id] !== undefined) {
        return localChecked.value[id]
      }
      return cartItemMap.value[id].is_checked == '1'
    })
  }

  return {
    setLocalChecked,
    getChecked,
  }
}

