<template>
  <div
    v-if="showPromotion"
    v-expose="analysisData('expose_cartpromotionadd.page_cart')"
    class="full-type-group j-full-add-on-header header-platform"
    :class="{'j-top-add-on': isSingleMall && !showAdd && !isPicked }"
  >
    <div
      class="cart_item-header  item-header__add-on ele-wrapper"
    >
      <section
        class="cart_item-banner"
        tabindex="0"
        :class="{'no-border': showCondition == 'picked', 'is-single-mall': isSingleMall}"
      >
        <div class="title-action-operation">
          <i
            class="promotion-item-icon item-content-icon"
          >
            <sui_icon_activity_18px size="18px" />
          </i>
          <div
            class="promotion-tip-time"
            :class="{'is-single-mall': isSingleMall}"
          >
            <!-- 提示 -->
            <p
              class="cart_item-desc"
              v-html="promotionInfo.promotionTips && promotionInfo.promotionTips.html_text || ''"
            >
            </p>
            <!-- 倒计时 -->
            <div
              v-if="promotionInfo.data.is_count_down && promotionInfo.data.end_time"
              class="client-only-height down-time"
            >
              <ClientOnly>
                <count-down-time :time-stamp="+promotionInfo.data.end_time" />
              </ClientOnly>
            </div>
          </div>
          <div
            class="cart_item-action"
            @click="handleAddBuyAll"
          >
            <span>{{ actionText }}</span>
            <sui_icon_more_right_12px
              size="12px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </div>
      </section>
    </div>
    <!--   吸顶元素高度占位     -->
    <div class="j-top-act-height"></div>
  </div>
</template>
<script>
export default {
  name: 'FullAddOnHeader',
}
</script>
<script setup>
import { computed } from 'vue'
import { sui_icon_more_right_12px, sui_icon_activity_18px } from '@shein-aidc/icon-vue2'
import CountDownTime from './../common/CartCountDownTime.vue'
import ClientOnly from 'vue-client-only'
import { useStore } from '../../hooks/useStore'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const { commit } = useStore()
const AddItem = useCartAddItem()

const props = defineProps({
  isSingleMall: {     // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    type: Boolean,
    default: true
  },
  promotionInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: ''
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  showCondition: {
    type: String,
    default: '' // 单mall：notPick picked
  },
})


const showPromotion = computed(() => {
  return props.showCondition ?  (props.showCondition == 'picked' && isPicked.value) : true
})
const showAdd = computed(() => {
  return props.promotionInfo && props.promotionInfo.data?.isMeet != 1
})

const isPicked = computed(() => {
  return props.promotionInfo && props.promotionInfo.data?.isPicked == 1
})

const actionText = computed(() => {
  return showAdd.value ? props.language.SHEIN_KEY_PWA_15091 : isPicked.value ? props.language.SHEIN_KEY_PWA_15831 : props.language.SHEIN_KEY_PWA_15830
})

const handleAddBuyAll = () => {
  const promotionId = props.promotionInfo.data?.promotion_id
  if(!showAdd.value) {
    commit('changeAppendageDrawerStatus', { promotionId })
  } else {
    AddItem.show({
      popupInfoId: props.promotionInfo.data?.popupInfoId,
      state: 'promotion_add',
    })
  }
  daEventCenter.triggerNotice(analysisData('click_cartpromotionadd.page_cart'))
}

const analysisData = (id) => {
  let { promotion_id, type_id } = props.promotionInfo.data
  return {
    id,
    data: {
      id: promotion_id,
      typeId: type_id,
      isFullPromotion: showAdd.value ? 1 : 0,
      is_member_gift: 0,
    }
  }
}
</script>
<style lang="less">
.item-header__add-on{
 padding: 32/75rem 24/75rem 0;
  margin: 20/75rem 0 0;
  &.sticky-block{
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
  }
}
</style>
