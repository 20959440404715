<template>
  <div
    class="price-change__popover"
    :style="styles"
  >
    <div
      class="price-change__content"
      @click="handleShowDetails('body')"
    >
      <span class="price-change__text">
        {{ language.SHEIN_KEY_PWA_35218 }}
      </span>
      <span
        class="price-change__btn"
        @click.stop="handleShowDetails('details')"
      >
        {{ language.SHEIN_KEY_PWA_32886 }}
      </span>
    </div>
    <div
      class="price-change__close"
      @click="handleClosePopover"
      >
      <Icon
        name="sui_icon_close_16px_2"
        color="#C1C1C1"
        size="16px"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onDeactivated, onMounted, onUnmounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useStore } from '../../hooks/useStore'
import { usePriceChangePopoverStyle } from './usePriceChangePopoverStyle.js'
import { usePriceChangeCache } from './usePriceChangeCache.js'
import { usePriceChangeState } from './usePriceChangeState.js'

const { state, commit } = useStore()
const { popoverStyle, calcPopoverStyle } = usePriceChangePopoverStyle()
const { checkBeforeItem, updatePriceChangeState } = usePriceChangeState()
const { showPopoverCount, updatePriceChangeClosePopover, updatePriceChangeSessionClosePopover, updatePriceChangeShowPopoverCount } = usePriceChangeCache()
const language = computed(() => state.language)
const autoClosePopoverSeconds = computed(() => state.priceChangeState.autoClosePopoverSeconds)
const updateState = (payload) => commit('updateState', payload)
let timer = null

const styles = computed(() => ({
  '--left': popoverStyle.value.left,
  '--top': popoverStyle.value.top,
}))
const handleShowDetails = (button_type) => {
  updateState({
    key: 'showPriceChangeDetailsDrawer',
    value: true,
  })
  updatePriceChangeState({ showPopover: false })
  updatePriceChangeClosePopover(true)
  updatePriceChangeShowPopoverCount(showPopoverCount.value + 1)
  clearAutoClosePopover()
  click_pricechange_bubble(button_type)
}
const handleClosePopover = () => {
  updatePriceChangeState({ showPopover: false })
  updatePriceChangeSessionClosePopover(true)
  updatePriceChangeShowPopoverCount(showPopoverCount.value + 1)
  clearAutoClosePopover()
  click_pricechange_bubble('close')
}

const handleAutoClosePopover = () => {
  timer = setTimeout(() => {
    updatePriceChangeState({ showPopover: false })
    updatePriceChangeSessionClosePopover(true)
    updatePriceChangeShowPopoverCount(showPopoverCount.value + 1)
    clearAutoClosePopover()
  }, autoClosePopoverSeconds.value)
}

const clearAutoClosePopover = () => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
}

const click_pricechange_bubble = (button_type) => {
  daEventCenter.triggerNotice({
    id: 'click_pricechange_bubble.page_cart',
    extraData: {
      goods_id: checkBeforeItem.value?.product?.goods_id,
      cart_id: checkBeforeItem.value?.id,
      button_type,
    },
  })
}

const expose_pricechange_bubble = () => {
  daEventCenter.triggerNotice({
    id: 'expose_pricechange_bubble.page_cart',
    extraData: {
      goods_id: checkBeforeItem.value?.product?.goods_id,
      cart_id: checkBeforeItem.value?.id,
    },
  })
}

onBeforeMount(() => {
  calcPopoverStyle()
})
onMounted(() => {
  expose_pricechange_bubble()
  handleAutoClosePopover()
})
onUnmounted(() => {
  clearAutoClosePopover()
})
onDeactivated(() => {
  clearAutoClosePopover()
})
</script>

<style lang="less">
.price-change {
  &__popover {
    display: flex;
    position: absolute;
    left: var(--left);
    top: var(--top);
    transform: translateY(calc(-100% - 5px));
    width: fit-content;
    background: rgba(0, 0, 0, .8);
    border-radius: 2/37.5rem;
    &:after {
      position: absolute;
      left: 10/37.5rem;
      top: 99%;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5/37.5rem 5/37.5rem 0 5/37.5rem;
      border-color: rgba(0, 0, 0, .8) transparent transparent transparent;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 6/37.5rem 0 6/37.5rem 8/37.5rem;
  }
  &__text {
    font-size: 12px;
    line-height: 14/37.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #fff;
  }
  &__btn {
    flex-shrink: 0;
    display: inline;
    margin-left: 8/37.5rem;
    border: 0.5px solid #fff;
    border-radius: 22/37.5rem;
    height: 22/37.5rem;
    padding: 0 6.5/37.5rem;
    max-width: 66/37.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    line-height: 22/37.5rem;
  }
  &__close {
    flex-shrink: 0;
    width: 22/37.5rem;
    position: relative;
    height: 100%;
    > span {
      position: absolute;
      right: 2/37.5rem;
      top: 2/37.5rem;
    }
  }
}
</style>