<template>
  <div 
    class="promo-bar" 
    :class="{
      'promo-bar_activity': isBlackFriday
    }"
  >
    <section 
      v-if="showFullGiftsList"
      class="full-gifts"
    >
      <Icon
        class="close-gif-list-icon"
        name="sui_icon_close_18px"
        size="14px" 
        color="#666"
        @click="closeGiftsList"
      />
      <FullGiftList
        :gift-list="headInfoData.promotionGoods"
        :promotion-id="headInfoData.promotion_id"
        :disabled-tip="showAdd ? htmlTips : ''"
        :promotion-type="headInfoData.type_id"
      />
    </section>
    <div class="wrapper">
      <slot name="prefix">
        <Icon
          class="ico"
          :name="iconName"
          is-responsive-name
          size="16px" 
        />
      </slot>
      <div
        class="middle"
      >
        <div class="tips">
          <span
            v-if="headInfoData.newUserReturnCouponTips"
            class="tag"
          >
            {{ headInfoData.newUserReturnCouponTips }}
          </span>
          <span v-html="htmlTips"></span>
          <Icon
            v-if="showReditRewardIcon"
            class="redit-reward-icon"
            name="sui_icon_doubt_12px_2"
            color="#959595"
            size="12px"
            @click="showNotice"
          />
        </div>
        <div
          v-if="showProgress"
          class="process"
        >
          <div
            class="process-bg"
            :style="{ width: `${progressVal}%` }"
          ></div>
        </div>
        <div
          v-if="showCountDown"
          :class="{'float-count-down':floatCountDown}"
        >
          <CountDown
            :time-stamp="timeStamp"
            :type="countDownType"
          />
        </div>
      </div>
      <div 
        v-if="actionText"
        class="suffix"
        @click="handleAction"
      >
        <span>
          {{ actionText }}
        </span>
        <Icon
          name="sui_icon_more_right_12px"
          size="12px" 
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>

    <template v-if="noticeVisible">
      <s-dialog
        class="credit-reward-notice"
        :visible.sync="noticeVisible"
        show-close
        append-to-body
      >
        <template slot="title">
          {{ creditRewardDialogInfo.title }}
        </template>
        <div v-html="creditRewardDialogInfo.content">
        </div>
        <template
          slot="footer"
        >
          <s-button 
            :type="['primary', 'H72PX']"
            width="100%"
            @click="noticeVisible = false"
          > 
            {{ creditRewardDialogInfo.btnText }}
          </s-button>
        </template>
      </s-dialog>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import CountDown from 'public/src/pages/cart_v2/components/common/countDown.vue'
import { getTypeStatus } from './utils'
const { GB_cssRight } = gbCommonInfo
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import FullGiftList from 'public/src/pages/cart_v2/components/cartList/FullGiftList.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const GIFT_CLOESD_KEY = 'GIFT_CLOESD_KEY'

export default {
  setup() {
    const { state, getters, commit } = useStore()
    const language = computed(() => state.language)
    const isShowCartActivityColor = computed(() => getters.isShowCartActivityColor)
    const toggleCartCouponHelperDrawer = (payload) => commit('toggleCartCouponHelperDrawer', payload)

    return {
      language,
      isShowCartActivityColor,
      toggleCartCouponHelperDrawer
    }
  },
  components: {
    Icon,
    CountDown,
    FullGiftList,
  },
  props: {
    showWay: {
      type: String,
      default: '-',
    },
    promoInfo: {
      type: Object,
      default: () => ({})
    },
    floatCountDown: {
      type: Boolean,
      default: false,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['handleAddOn', 'handlePickItem'],
  data() {
    return {
      GB_cssRight,
      noticeVisible: false,
      isGiftListClosed: true,
    }
  },
  computed: {
    isBlackFriday() {
      return this.isShowCartActivityColor && !this.isInModal
    },
    htmlTips() {
      return this.promoInfo?.groupHeadInfo?.promotionTips?.html_text || ''
    },
    headInfoData() {
      return this.promoInfo?.groupHeadInfo?.data || {}
    },
    typeStatus() {
      return getTypeStatus(this.headInfoData.type_id)
    },
    iconName() {
      if (this.showAdd) {
        if (this.typeStatus.isFullCoupon) return 'sui_icon_coupon_discount_18px'
        if (this.typeStatus.isReturnCoupon && this.headInfoData.coupon_num > 1) return 'sui_icon_couponbundle_16px'
        return this.typeStatus.isFreeGift ? 'sui_icon_gift_18px' : 'sui_icon_activity_18px'
      }
      if (this.typeStatus.isAccessoryPromo && !this.isPicked) {
        return this.typeStatus.isFreeGift ? 'sui_icon_gift_18px' : 'sui_icon_activity_18px'
      }
      return 'sui_icon_selected_16px'
    },
    showAdd() { // 是否 Add
      return this.headInfoData.isMeet != 1
    },
    isPicked() { // 是否 选择附属品
      return this.headInfoData.isPicked == 1
    },
    actionText() { // 操作按钮文案
      if (this.typeStatus.isCreditReward) return null
      if (this.showAdd) return this.language.SHEIN_KEY_PWA_15091
      if (this.typeStatus.isFullCoupon) return this.language.SHEIN_KEY_PWA_18965
      if (this.typeStatus.isAccessoryPromo) return this.isPicked ? this.language.SHEIN_KEY_PWA_15831 : this.language.SHEIN_KEY_PWA_15830
      return null
    },
    progressVal() { // 进度
      const { progressDiffAmount, additionInfoList, range, next } = this.headInfoData
      if (next == 0) return 100
      let targetPrice = Number(additionInfoList?.[range]?.showTitlePrice?.amount)
      let diffPrice = Number(progressDiffAmount?.amount)
      return (targetPrice - diffPrice) / targetPrice * 100
    },
    showProgress() { // 是否展示进度
      return this.progressVal < 100 && (this.typeStatus.isReturnCoupon || this.typeStatus.isFullCoupon)
    },
    showCountDown() { // 是否展示倒计时
      return this.headInfoData.is_count_down == 1 && this.headInfoData.end_time
    },
    timeStamp() {
      return +this.headInfoData.end_time || 0
    },
    countDownType() { // 倒计时类型
      if (this.isBlackFriday) return 'black'
      return this.floatCountDown ? 'orange' : 'white'
    },
    creditRewardDialogInfo() {
      return this.headInfoData.questionPopupInfo || {}
    },
    showReditRewardIcon() {
      return this.typeStatus.isCreditReward && !this.showAdd
    },
    showFullGiftsList() {
      return this.typeStatus.isFreeGift && this.floatCountDown && !this.isGiftListClosed && !this.isPicked && this.headInfoData.promotionGoods?.length > 0
    }
  },
  mounted() {
    if (this.typeStatus.isFullCoupon) {
      this.sendAllCouponSa('1-8-7-61')
    } else {
      this.sendPromoSa('expose_cartpromotionadd.page_cart')
    }
    
    if (window && window.sessionStorage) {
      this.isGiftListClosed = JSON.parse(window.sessionStorage.getItem(GIFT_CLOESD_KEY))
    }
  },
  methods: {
    handleAction() {
      if (this.typeStatus.isFullCoupon) {
        this.sendAllCouponSa('1-8-7-62')
      } else {
        this.sendPromoSa('click_cartpromotionadd.page_cart')
      }

      if (this.showAdd) {
        this.handleAddOn(this.headInfoData)
        return
      }
      if (this.typeStatus.isFullCoupon) {
        this.toggleCartCouponHelperDrawer({ value: true })
      }
      if (this.typeStatus.isAccessoryPromo) {
        this.handlePickItem(this.headInfoData.promotion_id)
      }
    },
    sendAllCouponSa(daId) {
      daEventCenter.triggerNotice({
        daId,
        extraData: {
          is_satisfied: this.showAdd ? 0 : 1,
          code: this.headInfoData?.addItemParams?.couponCode || '-',
        }
      })
    },
    sendPromoSa(daId) {
      const { type_id, coupon_num, newUserReturnCouponTips } = this.headInfoData
      const extraData = {
        typeId: type_id,
        isFullPromotion: this.showAdd ? 1 : 0,
        show_position: this.floatCountDown ? 2 : 3,
        show_way: this.showWay,
        is_member_gift: this.typeStatus.isClubGiftPromo,
        is_couponbag: type_id == 21 && coupon_num > 1,
        newuser_label: type_id == 21 && newUserReturnCouponTips
      }
      daEventCenter.triggerNotice({
        id: daId,
        data: extraData,
      })
    },
    showNotice() {
      this.noticeVisible = true
    },
    closeGiftsList() {
      this.isGiftListClosed = true
      window && window.sessionStorage.setItem(GIFT_CLOESD_KEY, JSON.stringify(this.isGiftListClosed))
    },
  },
}
</script>

<style lang="less" scoped>
.promo-bar {
  position: relative;
  background: @sui_color_promo_bg;
  .wrapper {
    padding: .27rem .32rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ico {
    padding-right: .21rem;
  }
  .middle {
    flex: 1;
    text-align: left;
    .tips {
      color: #222;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: initial;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* stylelint-disable-next-line selector-max-specificity */
      .tag {
        display: inline-block;
        padding: 0 8/75rem;
        margin-right: 8/75rem;
        max-width: 152/75rem;
        border-radius: 2px;
        background: linear-gradient(93deg, #FF858F -10.15%, #FF6A76 -10.14%, #FF3351 33.79%, #FF3351 75.48%, #EF2649 113.06%), linear-gradient(90deg, #FF858F 0%, #FF3351 100%), linear-gradient(57deg, #FF63C2 -35.79%, #FF4142 41.52%, #FFFC33 108.14%);
        font-size: 20/75rem;
        color: @sui_color_white;
        line-height: 28/75rem;
        vertical-align: text-bottom;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    /deep/ img {
      position: relative;
      top: -1px;
    }
    .process {
      width: 100%;
      height: .08rem;
      background-color: @sui_color_gray_light2;
      border-radius: 2px;
      margin: .1067rem 0;
    }
    .process-bg {
      height: .08rem;
      background-color: @sui_color_main;
      border-radius: 2px;
      transition: width .5s linear;
    }

    .redit-reward-icon {
      position: relative;
      top: -2px;
      padding-left: 4/37.5rem;
    }
  }
  .suffix {
    padding-left: .32rem;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    color: #222;
  }

  .float-count-down {
    position: absolute;
    right: 0rem;
    top: -0.34rem;
  }

  .full-gifts {
    position: relative;
    /* stylelint-disable-next-line selector-class-pattern */
    .close-gif-list-icon {
      line-height: 1;
      position: absolute;
      right: 6/37.5rem;
      top: 6/37.5rem;
    }
    /deep/ .cart__fullgifts-list {
      padding: 24/37.5rem 12/37.5rem 4/37.5rem;
    }
  }
}

.credit-reward-notice /deep/ .S-dialog__title {
  padding-bottom: 12/37.5rem;
}

.promo-bar.promo-bar_activity {
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  background: linear-gradient(0deg, #FF5029 0%, #FF5029 100%), linear-gradient(91deg, #ED3C24 4.36%, #FA772D 70.9%, #E11B33 99.78%);
  color: @sui_color_white;
  .process {
    background-color: @sui_color_white_alpha60;
  }
  .process-bg {
    background-color: @sui_color_white;
  }
  .tips, .suffix {
    color: @sui_color_white;
  }
  /deep/ font {
    color: @sui_color_white;
    font-weight: bold;
  }
}
</style>
