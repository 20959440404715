import { useStore } from './useStore'
import { computed } from 'vue'
import { sumBy } from 'lodash'
import AddOn from 'public/src/pages/common/add-on/index.js'

const useCartAddItem = () => {
  const cartStore = useStore()
  const recommenQueryInfo = computed(() => {
    const products = cartStore?.state?.carts?.map((item) => item.product) || []
    const limit = cartStore?.state?.cartAbtInfo?.Quickshiprecommendthreshold?.param?.quickship_recommend_threshold || 0
    const sum = sumBy(products.filter(item => Boolean(item.is_checked) && item.quick_ship == 1), item => +(item.unitPrice?.usdAmount || 0))

    return {
      sceneId: 152,
      goodsIds: products.map((v) => v.goods_id),
      cateIds: products.map((v) => v.cat_id),
      quickship_prior: sum > limit ? 1 : 0,
      mallCode: cartStore?.getters?.['singleMallCode'],
    }
  })

  const isMiniCart = computed(() => cartStore?.state?.moduleName == 'mini_cart')
  const miniCartStateTransformMap = {
    'coupon_add': 'ns_all_coupon_add',
    'coupon_helper_add': 'ns_coupon_add',
    'promotion_add': 'ns_promotion_add',
    'shipping_add': 'ns_shipping_add',
  }

  // 交易场景以下场景可跳转商详: 购物车券助手、购物车免邮活动、购物车促销活动
  const canToDetail = (state) => {
    return ['shipping_add', 'promotion_add', 'coupon_helper_add'].includes(state) && cartStore?.getters?.['cartAddTradeGoodsDetailSwitch']
  }

  const getAddOnPropsFromCart = ({ popupInfoId, state }) => {
    if (['promotion_add', 'ns_promotion_add'].includes(state)) {
      const promoItem = cartStore?.state?.promotionState?.cartPopUpInfoData?.promotionPopUpData?.find?.(item => item?.data?.promotionPopupInfo?.popupInfoId === popupInfoId)
      if (!promoItem) return null
      return AddOn.Helper.getPromotionProps({
        promotion: promoItem?.data,
        config: {
          isClickToDetail: canToDetail(state),
          excludePlugins: ['syncCartInfo'],
        },
        saInfo: {
          activity_from: state,
          state: state,
        }
      })
    }
    if (['shipping_add', 'ns_shipping_add'].includes(state)) {
      const freeShipItem = cartStore?.state?.promotionState?.cartPopUpInfoData?.shippingActivityPopUpData?.find?.(item => item?.popupInfoId === popupInfoId) || cartStore?.state?.promotionState?.cartPopUpInfoData?.shippingActivityPopUpData?.[0]
      if (!freeShipItem) return null
      return AddOn.Helper.getFreeShipProps({
        promotion: freeShipItem,
        config: {
          isClickToDetail: canToDetail(state),
          excludePlugins: ['syncCartInfo'],
        },
        saInfo: {
          activity_from: state,
          state: state,
        }
      })
    }
    return null
  }

  // 券凑单
  const handleCoupon = (opts) => {
    let isCartUpdated = false
    const { coupon, secondaryCoupon, state, config } = opts
    const couponProps = AddOn.Helper.getCouponProps({
      coupon,
      secondaryCoupon,
      config: {
        ...config,
        isClickToDetail: canToDetail(state),
        excludePlugins: ['syncCartInfo'],
      },
      queryInfo: recommenQueryInfo.value,
      saInfo: {
        activity_from: state,
        state: state,
      }
    })
    AddOn.open({
      props: couponProps,
      on: {
        close: () => {
          if (isCartUpdated) {
            cartStore?.dispatch('fetchCartIndex')
            // 券助手凑单加车后更新券数据
            if (opts.state == 'coupon_helper_add') {
              cartStore?.dispatch('fetchCouponInfo')
            }
          }
        },
        viewCart: (data) => {
          if (isCartUpdated) {
            cartStore?.dispatch('fetchCartIndex', { coupon_code: data?.couponInfo?.couponCode }) // 更新购物车信息
          }
          cartStore?.commit('toggleCartCouponHelperDrawer', { value: false }) // 关闭券助手
        },
        updateCart: () => {
          isCartUpdated = true
        }
      },
    })
  }

  // 免邮和活动凑单
  const handlePromotion = (opts) => {
    const initialProps = getAddOnPropsFromCart(opts)
    if (!initialProps?.type) return
    // console.log('购物车凑单初始化数据:=====', JSON.parse(JSON.stringify(initialProps.promotion)))
    AddOn.open({
      props: initialProps,
      on: {
        updateCart: async () => {
          const reqParams = {}
          if (['shipping_add', 'ns_shipping_add'].includes(initialProps?.saInfo?.state)) { // 传给中间层，用于判断免邮是否更换了活动
            reqParams.njActivityId = initialProps?.promotion?.njActivityId
            reqParams.njActivityType = initialProps?.promotion?.njActivityType
            reqParams.now_level = initialProps?.promotion?.now_level
          }
          await cartStore?.dispatch('fetchCartIndex', reqParams)
          const updatedProps = getAddOnPropsFromCart(opts)
          if (updatedProps?.promotion) {
            AddOn.updateOpts({ promotion: updatedProps.promotion })
          }
        },
        pickItem: (payload) => {
          if (!payload?.promotionId) return
          cartStore?.commit('changeAppendageDrawerStatus', { 
            promotionId: payload.promotionId,
            fromType: payload.fromType
          })
        },
      },
    })
  }

  return {
    show(options) {
      // console.log('购物车打开凑单参数:=====', options)
      if (options?.coupon) {
        handleCoupon({
          ...options,
          state: isMiniCart.value ? miniCartStateTransformMap[options.state] : options.state
        })
      } else {
        handlePromotion({
          ...options,
          state: isMiniCart.value ? miniCartStateTransformMap[options.state] : options.state
        })
      }
    }
  }
}

export { useCartAddItem }
