<template>
  <span 
    class="promo-num"
    :class="{
      'promo-num_activity': isShowCartActivityColor,
    }"
  >
    <Icon name="sui_icon_activity_18px" />
    <span class="act-number">{{ props.num }}</span>
    <Icon name="sui_icon_more_up_12px_2" />
  </span>
</template>
<script>
export default {
  name: 'PromoNum',
}
</script>
<script setup>
import { Icon } from '@shein-aidc/icon-vue2'
import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
const { getters } = useStore()

const isShowCartActivityColor = computed(() => {
  return getters['isShowCartActivityColor']
})

const props = defineProps({
  num: {
    type: Number,
    default: 0,
  }
})
</script>
<style lang="less" scoped>
.promo-num {
  line-height: 1;
  margin-right: 0.21rem;
  display: flex;
  align-items: center;
  border: 1px solid rgba(34, 34, 34, 0.4);
  border-radius: 16px;
  padding: .10rem .15rem;
  .act-number {
    font-size: 12px;
    padding: 0 .05rem;
  }
  // 扩大点击热区
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80/37.5rem;
    height: 100%;
  }
}
.promo-num_activity {
  border-color: rgba(255, 255, 255, 0.40);
}
</style>
