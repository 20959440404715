import BasePopoverManager from './BasePopoverManager'
import {
  BOTTOM_PROMOTION_TRANSFORMATION,
  NEW_GOODS_RECOMMENDED,
  NEW_GOODS_RECOMMENDED_C,
  PROMOTION_COUPON_MIX,
  CART_ITEM_GUIDE_ADD_ON,
  TOP_COUPON_FILTER_ADD_ON,
  PRICE_CHANGE,
  BOTTOM_COUPON_FILTER_CHECKOUT,
  LOW_EFFICIENCY_GUIDE_RECOMMENDED,
  CART_ITEM_LOW_STOCK,
} from './popoverTypes'

/**
 * 购物车气泡管理器
 */
class PopoverManager extends BasePopoverManager {
  constructor() {
    super({
      priority: [
        CART_ITEM_LOW_STOCK,
        NEW_GOODS_RECOMMENDED,
        BOTTOM_PROMOTION_TRANSFORMATION,
        PROMOTION_COUPON_MIX,
        CART_ITEM_GUIDE_ADD_ON,
        TOP_COUPON_FILTER_ADD_ON,
        PRICE_CHANGE,
        BOTTOM_COUPON_FILTER_CHECKOUT,
        LOW_EFFICIENCY_GUIDE_RECOMMENDED,
        NEW_GOODS_RECOMMENDED_C,
      ]
    })
  }
}

const popoverManagerInstance = new PopoverManager()
if (typeof window !== 'undefined') window.popoverManagerInstance = popoverManagerInstance
export default popoverManagerInstance
