<template>
  <div 
    ref="cardGoodsItem"
    :class="[
      'cart-list__item cart-list-item',
      `j-cart-item-${item.id}`,
    ]"
  >
    <!-- 商品行新品推荐气泡 -->
    <NewGoodsRecommendPopover v-if="showNewGoodsRecommendPopover && !isHideComponent('NewGoodsRecommendPopover')" />
    <!-- 商品行勾选后凑单气泡 -->
    <CartItemGuideAddOnPopover
      v-if="!batchActive && showCartItemGuideAddOnPopover && !isHideComponent('CartItemGuideAddOnPopover')"
      :item="item"
      @clear-cart-item-guide-on-timeout="emits('clear-cart-item-guide-on-timeout')"
    />
    <!-- 商品行低库存气泡 -->
    <CartLowStockPopover v-if="showCartLowStockPopover" />
    <ClientOnly>
      <CommentsWrapper
        ref="commentsWrapper"
        :item="item"
      />
    </ClientOnly>

    <div
      ref="swiperContainer"
      :class="[
        'cart-swiper',
        { 'not-allow__touch-move': !allowTouchMove }
      ]"
      @scroll.passive="scrollEventHandle"
    >
      <div class="cart-swiper-slide item-content">
        <div
          :class="['cart-item-v2', 'cart-list__item-container']"
          :style="itemStyle"
        >
          <div
            v-if="batchActive"
            class="cart-list__item-checkbox"
          >
            <SCheckboxGroup
              v-model="state.batchEditItemIds"
              class="batch-checkbox"
            >
              <div @click="handleClickItemBatchCheck">
                <SCheckbox
                  :label="item.id"
                  :disabled="item.isNewcomerItem"
                  :margin-r="isNewCartrowVision ? 16 / 75 + 'rem' : 20 / 75 + 'rem'"
                />
              </div>
            </SCheckboxGroup>
          </div>
          <div 
            v-show="!batchActive && !notNeedCheckbox"
            class="cart-list__item-checkbox"
            :style="{ opacity: (item.aggregateProductBusiness.isPresent == '1' || item.isPayMemberGift == '1') ? 0 : 1 }"
            @click="onClickCheckbox"
          >
            <s-checkbox
              :label="item.id"
              :disabled="item.isDisabled"
              :value="checked"
              :margin-r="isNewCartrowVision ? 16 / 75 + 'rem' : 20 / 75 + 'rem'"
              @change="(res) => handleCheckedItem(res, item)"
            />
          </div>
          <div class="cart-list__item-content">
            <BMainCartItem
              :key="item.id"
              class="bs-cart-item"
              :constant="constant"
              :language="language"
              :use="use"
              :data="item"
              :config="config"
              @expose="handleExposeItem"
            />
            <CartItemGuideAddOnFloor
              v-if="showCartItemGuideAddOnFloor && !isHideComponent('CartItemGuideAddOnFloor')"
              class="select-guide-add-on-floor"
              :item="item"
            />
          </div>
        </div>
        <PriceChangePopover
          v-if="showPopover && item.id === showPopoverId && !isHideComponent('PriceChangePopover')"
        />
      </div>
      <div
        ref="cartSwiperOperationTab"
        :class="[
          'operation-tab',
          'j-operation-tab',
          'cart-swiper-slide',
        ]"
      >
        <div
          v-if="isShowSwiperSimilar && !isHideComponent('swiperFindSimilar')"
          ref="findSimilar"
          v-expose="expose_left_findsimilar(item)"
          class="opertation-item similar"
          aria-hidden="true"
          @click="onSwiperSimilar"
        >
          <span class="opertation-text">{{ language.SHEIN_KEY_PWA_16789 }}</span>
        </div>
        <div
          v-if="isShowSwiperM2W"
          ref="moveToWishlistButton"
          class="opertation-item move-to-wishlist"
          aria-hidden="true"
          @click="onSwiperM2W"
        >
          <span class="opertation-text">{{ language.SHEIN_KEY_PWA_33673 }}</span>
        </div>
        <div
          v-if="isShowSwiperShare && !isHideComponent('swiperShare')"
          ref="shareButton"
          v-expose="expose_share(item)"
          class="opertation-item share"
          aria-hidden="true"
          @click="onSwiperShare"
        >
          <span class="opertation-text">{{ language.SHEIN_KEY_PWA_14817 }}</span>
        </div>
        <div
          class="opertation-item delete"
          aria-hidden="true"
          @click="onSwiperDelete"
        >
          <span
            class="opertation-text"
          >{{ language.SHEIN_KEY_PWA_15502 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CartItem',
}
</script>
<script setup> 
import { computed, ref, watch, onMounted, inject, nextTick, onUnmounted } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useRouter } from '../../hooks/useRouter'
import {
  usePopLoader,
  CART_INCENTIVE_PRODUCT_LIST,
} from '../../hooks/usePopLoader'
import { formatToggleIncentiveProductListDialogInfo } from 'public/src/pages/cart_v2/components/incentiveProductListDrawer/format.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import { BMainCartItem } from '@shein-aidc/bs-cart-item-mobile-vue2'
import { Toast } from '@shein/sui-mobile'
import { throttle, template } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import { getProductDetailUrl } from 'public/src/pages/goods_detail_v2/utils/common.js'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { useCartDeleteDialog } from 'public/src/pages/cart_v2/components/cartDeleteDialog/useCartDeleteDialog'
import customDetailDialogBff from 'public/src/pages/common/customDetailDialogBff/index.js'
import useChecked from 'public/src/pages/cart_v2/hooks/useChecked'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { useItemEvents } from 'public/src/pages/cart_v2/hooks/item/index'
import { animationDelete, animationM2W } from 'public/src/pages/cart_v2/utils/index'
import { click_goods_del_button, click_left_findsimilar, expose_left_findsimilar, expose_share  } from 'public/src/pages/cart_v2/analysis/swiperOpertation.js'
import showCheckedTip from 'public/src/pages/cart_v2/components/checkedTip/showCheckedTip.js'
import { expose_goods_list, getSaGoodsList, click_goods_list } from 'public/src/pages/cart_v2/analysis/item/index.js'
import { getGoodsUrl, isLogin } from 'public/src/pages/common/utils/index.js'
import { getMarketingGmtcShareLink } from 'public/src/pages/cart_v2/utils/fetcher.js'
import { openCartShareDrawer, preloadCartShareImgs } from 'public/src/pages/common/cartShareDrawer/index.js'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import PriceChangePopover from 'public/src/pages/cart_v2/components/cartPriceChange/PriceChangePopover.vue'
import { usePriceChange } from 'public/src/pages/cart_v2/components/cartPriceChange/usePriceChange.js'
import { usePriceChangeState } from 'public/src/pages/cart_v2/components/cartPriceChange/usePriceChangeState.js'
import { useNewRecommendFlag } from 'public/src/pages/cart_v2/components/cartNewGoodsRecommend/useNewRecommendFlag.js'
import { useCartLowStockPopoverFlag } from 'public/src/pages/cart_v2/components/cartLowStock/useCartLowStockPopoverFlag.js'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'
import { useItemGuideAddOnState } from 'public/src/pages/cart_v2/components/cartItemGuideAddOn/useItemGuideAddOnState.js'
import { useItemCheckedChange } from 'public/src/pages/cart_v2/components/cartItemGuideAddOn/useItemCheckedChange.js'
import ClientOnly from 'vue-client-only'
import CommentsWrapper from 'public/src/pages/cart_v2/components/cartComments/CommentsWrapper.vue'
import { useHideComponents } from 'public/src/pages/cart_v2/hooks/useHideComponents.js'
import { useTimeEndRefresh } from 'public/src/pages/cart_v2/hooks/useTimeEndRefresh.js'

const NewGoodsRecommendPopover = () => import(/* webpackChunkName: "cartv2-new-goods-recommend-popover" */'public/src/pages/cart_v2/components/cartNewGoodsRecommend/NewGoodsRecommendPopover.vue')
const CartItemGuideAddOnPopover = () => import(/* webpackChunkName: "cartv2-cart-item-guide-add-on-popover" */'@/public/src/pages/cart_v2/components/cartItemGuideAddOn/CartItemGuideAddOnPopover.vue')
const CartItemGuideAddOnFloor = () => import(/* webpackChunkName: "cartv2-cart-item-guide-add-on-floor" */'@/public/src/pages/cart_v2/components/cartItemGuideAddOn/CartItemGuideAddOnFloor.vue')
const CartLowStockPopover = () => import(/* webpackChunkName: "cartv2-cart-low-stock-popover" */'@/public/src/pages/cart_v2/components/cartLowStock/cartLowStockPopover.vue')

const { open: openCartIncentiveProductList } = usePopLoader({ name: CART_INCENTIVE_PRODUCT_LIST })
const AddItem = useCartAddItem()
const { getChecked } = useChecked()
const { state, getters, commit, dispatch } = useStore()
const { routerPush } = useRouter()
const { handlePriceChangeBefore, handlePriceChangeAfter } = usePriceChange()
const { showPopover, showPopoverId, resetCartPriceChangeState } = usePriceChangeState()
const { handleCheckedChange } = useItemCheckedChange()
const { showCartDeleteDailog } = useCartDeleteDialog()
const { isHideComponent } = useHideComponents()
const { refreshHanlder } = useTimeEndRefresh()
const emits = defineEmits(['clear-cart-item-guide-on-timeout'])

const destoryMiniCart = inject('destoryMiniCart', () => ({}))
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  },
  notNeedCheckbox: {
    type: Boolean,
    default: false
  },
  actionToRefreshRecommend: {
    type: Boolean,
    default: false
  },
})

const { showNewGoodsRecommendPopover } = useNewRecommendFlag(props.item)
const { showCartLowStockPopover } = useCartLowStockPopoverFlag(props.item)
const { showCartItemGuideAddOnPopover, showCartItemGuideAddOnFloor } = useItemGuideAddOnState(props.item)
let lastScrollLeft = 0

const isNewCartrowVision = computed(() => getters['isNewCartrowVision'])
const language = computed(() => state.language)
const locals = computed(() => state.locals)
const batchActive = computed(() => state.batchActive)
const openedDeleteItemId = computed(() => state.openedDeleteItemId)
const selectFilterLabel = computed(() => state.selectFilterLabel)
const wishlistInLeftSliding = computed(() => getters['wishlistInLeftSliding'])
const cartAbtInfo = computed(() => state.cartAbtInfo)
const loginStatus = computed(() => state.loginStatus)

const isAddBuyGoodsOrGiftGoods = computed(() => {
  return props.item.aggregateProductBusiness?.isPresent == '1' || props.item.aggregateProductBusiness?.isAddBuy == '1' || props.item.isPayMemberGift == '1'
})
const isSwitchItem = computed(() => {
  return props.item.canChangeMallInfo || props.item.canChangeGoodInfo
})
const isInvalid = computed(() => props.item.isInvalid == '1')

// 是否展示左滑找相似按钮
const isShowSwiperSimilar = computed(() => {
  return !batchActive.value && !isInvalid.value && !props.item.isNewcomerItem
})
// 是否展示左滑移至心愿单按钮
const isShowSwiperM2W = computed(() => {
  return !batchActive.value && !isAddBuyGoodsOrGiftGoods.value && (!isInvalid.value || (isInvalid.value && !isSwitchItem.value)) && wishlistInLeftSliding.value
})
// 是否展示左滑分享按钮
const isShowSwiperShare = computed(() => {
  return !isAddBuyGoodsOrGiftGoods.value && !isInvalid.value
})

const itemStyle = computed(() => ({
  '--item-gap': isNewCartrowVision.value ? '0.213rem' : '0.2667rem',
  '--template-left-width': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  '--template-left-height': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  '--checkbox-height': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
}))
const showQuickShip = computed(() => {
  return props.item?.aggregateProductBusiness?.productTags?.some(tag => tag.type === 'quickShip')
})
const checked = computed(() => {
  if (props.item.isDisabled) {
    return props.item.is_checked == '1'
  }
  return getChecked(props.item.id)
})

const originBehaviorTags = computed(() => props.item.product?.actTags?.filter(item => item.hasAvailableTag === '1' && item.actionDataTagShow > 0) || [])
const fixedTag = computed(() => originBehaviorTags.value?.find(item => item.tagType === 'ugctags'))
const userBehaviorLabels = computed(() => {
  if (fixedTag.value && behaviorlabelInfo.value.isShowFixedTag) return [fixedTag.value]
  return getReOrderLabels(fixedTag.value?.tagType, originBehaviorTags.value)
})

const click_add_collect = (defaultData) => {
  const postData = {
    index: props.index + 1,
    quickShip: showQuickShip.value ? 1 : 0
  }
  defaultData.postData = {
    ...defaultData.postData,
    ...postData
  }
  return {
    ...defaultData,
    source: wishlistInLeftSliding.value ? 'left' : 'row',
  }
}
const cardGoodsItem = ref(null)

const swiperContainer = ref(null)
const cartSwiperOperationTab = ref(null)
let allowTouchMove = ref(false)
const commentsWrapper = ref(null)
const behaviorlabelInfo = ref({
  isLoaded: false,
  isPause: false,
  isShowFixedTag: false,
})
const use = ref([
  'GoodsImg',
  'GoodsDiscountFlag',
  'GoodsBelt',
  'GoodsTitle',
  'GoodsTitleIcon',
  'GoodsSeriesFlag',
  'GoodsPrice',
  'GoodsAddWish',
  'GoodsAlert',
  'GoodsCustomContent',
  'GoodsDeleteButton',
  'GoodsSaleAttr',
  'ReducePriceHint',
  'GoodsQty',
  'IncentiveReducePrice',
  'GoodsStore',
  'BehaviorLabel',
  'ChangeMall',
  'QuickShipTag',
  'FreeShippingTag',
  'SFSTag',
  'EvoluTag',
  'VoucherTag',
  'PromotionTag',
  'DiscountNormalTag',
  'DiscountLimitTag',
  'EstimatedPrice',
  'GoodsNegativeInfo',
  'GoodsRepickButton',
  'FindSimilarText',
  'GoodsAlmostEntry',
  'GoodsAddOnTip',
  'LowStockLabel',
  'LowStockTag',
  'RankListTag',
  'HolidayDeliveryTag',
])

const handleResetCartPriceChangeState = () => {
  if (props.item.isInvalid == '0') {
    resetCartPriceChangeState()
  }
}
/**
 * 跳转商详页
 * @param data 商品行数据
 */
const jumpDetail = async ({ data }) => {
  if ( batchActive.value ) return
  const {
    mall_code,
    product: { goods_url_name, goods_id, cat_id, goods_img },
    aggregateProductBusiness,
    product
  } = data
  // 付费会员赠品售罄提示语
  const payMemberGiftStockOutTips = aggregateProductBusiness?.productTags?.find(tag => tag.type === 'payMemberGift' && tag.style == '-1')?.view?.text || ''
  if (payMemberGiftStockOutTips) {
    Toast(payMemberGiftStockOutTips)
    return
  }
  markPoint('toNextPageClick', 'public')
  click_goods_list(data, (defaultDate) => {
    return {
      ...defaultDate,
      index: props.index + 1
    }
  })
  commit('addProductDetailBfCache', product)
  const url = await getProductDetailUrl({
    imgSrc: goods_img,
    langPath: gbCommonInfo.langPath,
    goods_id,
    goods_url_name,
    cat_id,
    mall_code
  })
  routerPush(url)
}

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const onDeleteSuccess = async () => {
  handleResetCartPriceChangeState() 
  if (cardGoodsItem.value) {
    await animationDelete(cardGoodsItem.value)
  } 
}
const onM2WSuccess = async () => {
  handleResetCartPriceChangeState()
  const el = document.querySelector(`.j-cart-item-${props.item.id} .bsc-cart-item-goods-img__content`)
  // 兼容公共头部的心愿单按钮
  const to = document.querySelector('.cart-header__icon-right.j-wishlist-btn') || document.querySelector('.app-header__icon-right.bsc-wish-list-entry_wrap')
  await animationM2W(el, { to })
}

const onSwiperSimilar = () => {
  retractScroll()
  click_left_findsimilar(props.item)
  SimilarInstance.showModal(props.item.product)
}
const handleM2W = async () => {
  if (props.item.aggregateProductBusiness?.showNewUsersBonus == '1') {
    const { maxLimitPurchaseQuantity = '' } = props.item?.aggregateProductBusiness || {}
    if (!isNaN(+maxLimitPurchaseQuantity) && +maxLimitPurchaseQuantity > 0) {
      Toast(template(maxLimitPurchaseQuantity, language.value.SHEIN_KEY_PWA_26184))
      daEventCenter.triggerNotice({ daId: '1-8-3-34' })
    }
    return
  }
  daEventCenter.triggerNotice({
    daId: '1-8-3-36',
    extraData: {
      source: wishlistInLeftSliding.value ? 'left' : 'row'
    }
  })
  commit('handleModal', {
    text: language.value.SHEIN_KEY_PWA_17954,
    okCallback: async () => {
      if (loginStatus.value) {
        commit('updateState', {
          key: 'loadingShow',
          value: true
        })
        const events = {
          onSuccess: async () => {
            commit('updateState', {
              key: 'loadingShow',
              value: false
            })
            await onM2WSuccess()
          } 
        }
        try {
          const res = await dispatch('fetchCartBatchWish', { params: { id: [props.item.id] }, events })
          commit('updateState', {
            key: 'loadingShow',
            value: false
          })
          if (res.code != '0') {
            Toast(res.msg)
          }
          
          // 【埋点统一】收藏埋点的统一
          daEventCenter.triggerNotice({
            daId: '1-8-1-2',
            extraData: {
              result: { data: { code: res.code } },
              code: 'cart',
              mall_code: props.item.mall_code,
              postData: {
                action: 1, // 选中发1, 取消发0
                goods_id: props.item.product.goods_id,
                sku: props.item.product.goods_sn,
                detail: props.item.product,
                index: props.index + 1,
                quickShip: showQuickShip.value ? 1 : 0
              },
              from: 'cart',
              source: wishlistInLeftSliding.value ? 'left' : 'row',
            }
          })
          return res
        } catch (error) {
          commit('updateState', {
            key: 'loadingShow',
            value: false
          })
        }
      } else {
        const { result, res } = await dispatch('handleCartLogin', { type: 'wishlist' })
        if(res.code == 0){
          if (!result.equalCart) {
            Toast({
              content: language.value.SHEIN_KEY_PWA_17961,
              duration: 5000,
            })
          }
        }
      }
    },
    cancelCallback: () => {
    },
    cancelDaId: '1-8-7-19'
  })
}
const onSwiperM2W = () => {
  retractScroll()
  handleM2W()
}
const onSwiperShare = async () => {
  retractScroll()
  daEventCenter.triggerNotice({
    daId: '2-24-2',
    bindData: {
      share_type: 'page',
      content_id: props.item.product.goods_id
    }
  })
  const { PUBLIC_CDN, langPath, host, IS_RW, SiteUID } = gbCommonInfo
  let shareLink = ''
  let goodsLink = host + langPath + getGoodsUrl(props.item.product?.goods_url_name, props.item.product?.goods_id, props.item.product?.cat_id)

  preloadCartShareImgs()

  if(isLogin()){
    let json
    json = await getMarketingGmtcShareLink({
      shareUrl: goodsLink,
      pageType: 1,
    })
    if (json?.info?.url?.indexOf('url_from') > -1) {
      shareLink = encodeURIComponent(decodeURIComponent(json.info.url) + '&share_from=' + SiteUID)
    }
  }
  if (!shareLink) {
    shareLink = goodsLink + encodeURIComponent('?share_from=' + SiteUID)
  }

  openCartShareDrawer({
    title: language.value.SHEIN_KEY_PWA_25671,
    subDesc: language.value.SHEIN_KEY_PWA_25672,
    shareText: language.value.SHEIN_KEY_PWA_25704,
    shareToast: language.value.SHEIN_KEY_PWA_25660,
    shareLink,
    pinterestImg: props.item.product.goods_img,
    getSaData: data => {
      return {
        share_type: 'page',
        content_id: props.item?.product?.goods_id,
        channel: data.channel,
      }
    },
  })
}
const onSwiperDelete = () => {
  retractScroll()
  click_goods_del_button(props.item)
  showCartDeleteDailog({
    goodsData: props.item,
    type: 'single',
    language: language.value,
    isShowOverLimitToast: true,
    events: {
      onDeleteSuccess,
      onM2WSuccess,
    },
    analysis: {
      click_add_collect
    }
  })
}

const handleChangeMall = async (data) => {
  daEventCenter.triggerNotice({
    daId: '1-8-3-78',
    extraData: {
      sku_code: data.product.sku_code 
    }
  })
  const reqParams = {
    is_checked: data.is_checked,
    append_id_list: data.appendIds,
    sku_code: data.product?.sku_code,
    id: data.id,
    mall_code: data.aggregateProductBusiness?.changeMallInfo?.lastMallCode,
    quantity: data.quantity,
    goods_id: data.product?.goods_id,
  }
  commit('changeRootSLoadingStatus', {
    show: true,
    maskTransparent: true,
    type: 'curpage',
  })
  try {
    const res = await dispatch('fetchCartUpdateAttr', reqParams)
    if (res.code == '0') {
      dispatch('showOverLimitToast', { id: data.id, isShowOverLimitToast: true })
    } else {
      Toast(res.msg)
    }
  } catch {
    // 
  } finally {
    commit('changeRootSLoadingStatus', {
      show: false,
      maskTransparent: true,
      type: 'curpage',
    })
  }
}

const {
  discountLimitTagEvent,
  discountNormalTagEvent,
  estimatedPriceEvent,
  goodsQtyEvent,
  goodsSaleAttrEvent,
  promotionTagEvent,
  behaviorLabelEvent,
  rankLiskTagEvent,
} = useItemEvents()
const config = ref({
  GoodsNegativeInfo: {
    props() {
      if (isHideComponent('GoodsNegativeInfo')) {
        return {
          isShow: false
        }
      }
    }
  },
  GoodsPrice: {
    props() {
      return {
        pricePopoverConfig: {
          gapTarget: '.j-cart-footer'
        }
      }
    }
  },
  GoodsDiscountFlag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  BehaviorLabel: {
    position: !isNewCartrowVision.value ? 'top' : 'bottom',
    props() {
      return {
        labels: userBehaviorLabels.value,
      }
    },
    events: {
      onLabelExpose(itemOpts) {
        behaviorLabelEvent.onLabelExpose({ itemOpts }) 
      },
      onExpose() {
        behaviorlabelInfo.value.isLoaded = true
      }
    }
  },
  LowStockLabel: {
    position: !isNewCartrowVision.value ? 'top' : 'bottom',
    props() {
      return {
        replayTrigger: state.replayTrigger
      }
    }
  },
  LowStockTag: {
    props() {
      return {
        replayTrigger: state.replayTrigger
      }
    }
  },
  QuickShipTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  HolidayDeliveryTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  FreeShippingTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  EvoluTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  VoucherTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  SFSTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  GoodsImg: {
    props() {
      return {
        isLazyLoad: props.index >= 6,
        isFspElement: props.index <= 2
      }
    },
    events: {
      onClick({ data }) {
        destoryMiniCart()
        nextTick(() => {
          jumpDetail({ data: data.value })
        })
      }
    }
  },
  GoodsSeriesFlag: {
    props() {
      return {
        isLazyLoad: props.index >= 6,
      }
    }
  },
  GoodsTitle: {
    events: {
      onClick({ data }) {
        destoryMiniCart()
        nextTick(() => {
          jumpDetail({ data: data.value })
        })
      }
    }
  },
  GoodsAddWish: {
    props({ data }) {
      if (batchActive.value || wishlistInLeftSliding.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data, event }) {
        handleM2W()
      }
    }
  },
  GoodsCustomContent: {
    props() {
      if (batchActive.value || isHideComponent('GoodsCustomContent')) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ event }) {
        daEventCenter.triggerNotice({
          daId: '1-8-7-63',
          extraData: {
            images: event.images?.length || 0,
            preview: event.effects?.length || 0,
            texts: event.texts?.length || 0,
          }
        })

        customDetailDialogBff.open({
          ...event,
        })
      }
    }
  },
  GoodsDeleteButton: {
    props() {
      if (batchActive.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data }) {
        showCartDeleteDailog({
          goodsData: data.value,
          type: 'single',
          language: language.value,
          isShowOverLimitToast: true,
          events: {
            onDeleteSuccess,
            onM2WSuccess,
          },
          analysis: {
            click_add_collect
          }
        })
      }
    },
  },
  GoodsSaleAttr: {
    props({ data }) {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        goodsSaleAttrEvent.onClick({ itemOpts, options: { isShowOverLimitToast: true } })
      }
    }
  },
  GoodsQty: {
    props({}) {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onClickInput(itemOpts) {
        goodsQtyEvent.onClickInput({ itemOpts })
      },
      onDelete: (itemOpts) => {
        goodsQtyEvent.onDelete(
          {
            itemOpts,
            options: {
              isShowOverLimitToast: true,
              events: {
                onDeleteSuccess,
                onM2WSuccess,
              }
            }, 
            analysis: { click_add_collect } }
        )
      },
      onMinus(itemOpts) {
        goodsQtyEvent.onMinus({ itemOpts })
      },
      onPlus(itemOpts) {
        goodsQtyEvent.onPlus({ itemOpts })
      },
      onChange(itemOpts) {
        goodsQtyEvent.onChange({ itemOpts })
        handleResetCartPriceChangeState()
      },
    }
  },
  DiscountNormalTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        const analysis = {
          click_discount_details(data) {
            data.result_order = props.index + 1
            return data
          }
        }
        discountNormalTagEvent.onClick({ itemOpts, analysis })
      }
    }
  },
  DiscountLimitTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        discountLimitTagEvent.onClick({ itemOpts })
      },
      onCountDownEnd() {
        discountLimitTagEvent.onCountDownEnd()
      }
    }
  },
  EstimatedPrice: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        estimatedPriceEvent.onClick({ itemOpts })
      },
      onExposeChange(itemOpts) {
        estimatedPriceEvent.onExposeChange({ itemOpts })
      },
      onCountDownEnd() {
        estimatedPriceEvent.onCountDownEnd()
      }
    }
  },
  GoodsRepickButton: {
    props() {
      if (batchActive.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const promotionId = data.value?.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView?.promotionId
        if (promotionId) {
          commit('changeAppendageDrawerStatus', { promotionId })
        }
      }
    }
  },
  GoodsAlmostEntry: {
    props() {
      if (batchActive.value || isHideComponent('GoodsAlmostEntry')) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ event }) {
        if (!event) return
        if (event.type) {
          daEventCenterV2.triggerNotice({
            id: 'click_recommend_items.page_cart',
            data: {
              type: ['-', 'shortstock', 'cheaper', 'lowestprice'][event.type],
            },
          })
          const params = state.cartSameLurePopupInfo
          if (!params) return
          let cartLureList = params.cartLureList
          if (!cartLureList?.length) return
          cartLureList = cartLureList.map(item => ({ ...item })) // 拷贝
          cartLureList = cartLureList.sort(item => item.type === event.type ? -1 : 1) // 置顶
          const seenCartIds = new Set()
          cartLureList.forEach((list, index) => {
            if (index > 0) {
              const target = list.cartIds
              if (!target?.length) return
              list.cartIds = []
              target.forEach(id => {
                if (seenCartIds.has(id)) return // 去重
                seenCartIds.add(id)
                list.cartIds.push(id)
              })
            } else {
              list.cartIds?.forEach(id => seenCartIds.add(id))
            }
          })
          cartLureList = cartLureList.filter(list => !!list.cartIds?.length) // 过滤没有商品的利益点

          openCartIncentiveProductList({
            ...params,
            cartLureList,
          })
          return
        }
        openCartIncentiveProductList(formatToggleIncentiveProductListDialogInfo({
          ...event,
          pop_entrance_from: 'low_inventory',
        }))
      },
      onExpose({ event }) {
        daEventCenterV2.triggerNotice({
          id: 'expose_recommend_items.page_cart',
          data: {
            type: ['-', 'shortstock', 'cheaper', 'lowestprice'][event.type],
          },
        })
      },
    }
  },
  PromotionTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value || state.moduleName === 'mini_cart') {
        return {
          isClick: false,
          reloadOnTimeEnd: false,
        }
      }
      return {
        reloadOnTimeEnd: false,
      }
    },
    events: {
      onClick(itemOpts) {
        promotionTagEvent.onClick({ itemOpts, options: { index: props.index + 1 } })
      },
      onReport(itemOpts) {
        promotionTagEvent.onReport({ itemOpts, options: { index: props.index + 1 } })
      },
      onExpose(itemOpts) {
        promotionTagEvent.onExpose({ itemOpts, options: { index: props.index + 1 } })
      },
      onTimeEnd() {
        refreshHanlder()
      }
    }
  },
  FindSimilarText: {
    props() {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onExpose({ data }) {
        if (!batchActive.value) {
          daEventCenter.triggerNotice({
            daId: '1-8-3-5',
            extraData: {
              goods_id: data.value.product.goods_id,
              activity_from: 'cart',
              is_out_of_stock: 1
            }
          })
        }
      },
      onClick({ data }) {
        SimilarInstance.showModal(data.value.product, {
          similarFrom: 'out_of_stock',
        })
        // 上报埋点
        daEventCenter.triggerNotice({
          daId: '1-8-3-6',
          extraData: {
            sku: data.value.product.goods_sn,
            goods_id: data.value.product.goods_id,
            activeFrom: 'cart',
            code: 'cart',
            isOutOfStock: 1 
          }
        })
      },
    }
  },
  ChangeMall: {
    props() {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onExpose({ data }) {
        daEventCenter.triggerNotice({
          daId: '1-8-3-77',
          bindData: [
            {
              sku_code: data.value.product.sku_code
            }
          ]
        })
      },
      onClick({ data }) {
        handleChangeMall(data.value)
      }
    }
  },
  GoodsStore: {
    props() {
      if (state.moduleName === 'mini_cart') {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const storeRouting = data.value.storeRouting
        if (storeRouting) {
          markPoint('toNextPageClick', 'public')
          routerPush(`${gbCommonInfo.langPath}${storeRouting}`)
        }
      }
    }
  },
  RankListTag: {
    props() {
      if(batchActive.value || state.moduleName === 'mini_cart') {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        rankLiskTagEvent.onClick({ itemOpts, options: { index: props.index + 1 } })
      },
      onExpose(itemOpts) {
        rankLiskTagEvent.onExpose({ itemOpts, options: { index: props.index + 1 } })
      },
      onReport(itemOpts) {
        rankLiskTagEvent.onReport({ itemOpts, options: { index: props.index + 1 } })
      },
    }
  },
  GoodsAddOnTip: {
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const promotionId = data.value?.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView?.promotionId
        if (promotionId) {
          AddItem.show({
            popupInfoId: promotionId,
            state: 'promotion_add',
          })
        }
        
      }
    }
  }
})
const constant = ref({
  RESOURCE_SDK: locals.value.RESOURCE_SDK,
  LAZY_IMG: locals.value.LAZY_IMG,
  GBCssRight: locals.value.GB_cssRight,
})

watch(() => batchActive.value, (n) => {
  if (!n) {
    allowTouchMove.value = true
    return
  }
  if (openedDeleteItemId.value === props.item.id) {
    retractScroll()
  }
  allowTouchMove.value = false
  // this.$refs?.itemBehavior?.changeAutoPlayStatus(!n) 这个需要对行为标签原子组件进行改造。传入一个参数，控制是否播放
})

watch(() => openedDeleteItemId.value, (n) => {
  if(n && n !== props.item.id){
    retractScroll()
  }
})

const retractScroll = () => {
  swiperContainer.value?.scrollTo({
    left: 0,
    behavior: 'smooth',
  })
}

const handleExposeItem = () => {
  if (batchActive.value) return
  expose_goods_list(props.item, (defaultData) => {
    const data = {
      ...defaultData,
      label_id: selectFilterLabel.value?.labelId || '',
      goods_list: getSaGoodsList(props.item, {
        index: props.index,
        cartAbtInfo: cartAbtInfo.value,
        scene: 'cart',
        el: cardGoodsItem.value,
        actionToRefreshRecommend: props.actionToRefreshRecommend,
        daMoreInfo: true, // 上报更多信息（高度、行数、商品行信息）
      }),
      src_module: 'trade_display',
      ranking_list_identifier: props.item?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.rankListIdentifier,
      board_generate_type: props.item?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.boardGenerateType,
    }
    return data
  })
}

const handleCheckedItem = ({ label = '', checked = false }, item) => {
  handlePriceChangeBefore(item)
  modifyCartCheckStatus({
    cartIdList: [item.id],
    operationType: checked ? 1 : 2,
    type: 'item',
    isShowOverLimitToast: true,
    onAfterModify() {
      handlePriceChangeAfter(props.item)
      handleCheckedChange(props.item)
       
      if (checked) {
        dealWithCheckedAnimation()
      }
    }
  })
}
const onClickCheckbox = () => {
  if (isSwitchItem.value) {
    const btnText = props.item.canChangeMallInfo ? props.item.aggregateProductBusiness?.changeMallInfo?.buttonTip :  props.item.aggregateProductBusiness?.changeGoodInfo?.buttonTip
    const content = props.item.canChangeMallInfo ? props.item.aggregateProductBusiness?.changeMallInfo?.toastTip :  props.item.aggregateProductBusiness?.changeGoodInfo?.toastTip
    daEventCenter.triggerNotice({
      daId: '1-8-3-79',
      extraData: {
        type: props.item.canChangeMallInfo ? 1 : 2,
      }
    })
    const events = {
      onClick: async () => {
        daEventCenter.triggerNotice({
          daId: '1-8-3-80',
          extraData: {
            type: props.item.canChangeMallInfo ? 1 : 2,
          }
        })
        if (props.item.canChangeMallInfo) {
          handleChangeMall(props.item)
        } else if (props.item.canChangeGoodInfo) {
          goodsSaleAttrEvent.onClick({ itemOpts: { data: ref(props.item) } })
        }
      }
    }
    showCheckedTip({
      btnText,
      content,
    }, events)
  }
}

const scrollEventHandle = throttle({
  func: function(ev) {

    if (commentsWrapper.value?.isActive) {
      commentsWrapper.value?.endBarrageAnimation?.()
    }
    let width = cartSwiperOperationTab.value.offsetWidth
    let currentScrollLeft = locals.value.GB_cssRight ? Math.abs(ev.target.scrollLeft) - 1 : ev.target.scrollLeft //lastScrollLeft
    commit('updateState', { key: 'scrolledItemId', value: props.item.id })
    if (currentScrollLeft >= (width * 3) / 4) {
      if((currentScrollLeft - lastScrollLeft) < 0){
        return
      }
      lastScrollLeft = currentScrollLeft
      commit('updateState', { key: 'openedDeleteItemId', value: props.item.id })
    } else if (currentScrollLeft <= 0) {
      if(openedDeleteItemId.value && openedDeleteItemId.value !== props.item.id){
        return
      }
      commit('updateState', { key: 'openedDeleteItemId', value: '' })
    }
  },
  wait: 300
})

const handleClickItemBatchCheck = () => {
  if (props.item.isNewcomerItem ) {
    Toast(language.value.SHEIN_KEY_PWA_26189)
    daEventCenter.triggerNotice({ daId: '1-8-3-35' })
    return
  }
}

// 勾选引导凑单场景埋点上报
const daEventCenterSelectGuideAddOn = () => {
  const selectGuideAddOnBiData = props.item.aggregateProductBusiness?.productRowBiData?.guideAddOnItemBiData || {}
  if (selectGuideAddOnBiData.scenes) {
    const { scenes, goodsId, type } = selectGuideAddOnBiData
    exposeScenesAbtAggregator.addEvent({
      daId: '1-8-7-60',
      extraData: {
        scenes: scenes,
        type: type,
        goods_id: goodsId
      }
    })
  }
}

/** 
 * a.展示评论弹幕效果
 * b.用户行为标签固定到关联标签，等展示弹幕后继续轮播
 */
const dealWithCheckedAnimation = () => {
  const productBulletScreens = props.item.aggregateProductBusiness?.productBulletScreens
  if (!productBulletScreens?.length) return
  daEventCenterV2.triggerNotice({ 
    id: 'expose_danmu.page_cart',
    data: {
      goods_id: props.item.product.goods_id,
      comments_tag: productBulletScreens?.map?.(v => v.biData)?.join(','),
    }
  })

  const needFixedLabel = behaviorlabelInfo.value.isLoaded && fixedTag.value && userBehaviorLabels.value?.length > 1
  if (needFixedLabel) {
    behaviorlabelInfo.value.isShowFixedTag = true
  }
  commentsWrapper.value?.startBarrageAnimation().then(() => {
    if (needFixedLabel) {
      behaviorlabelInfo.value.isShowFixedTag = false
    }
  })
}
/** 对用户行为标签重新排序
 * 执行: fn(d, [a, b, c, d, e]) 结果: [d, e, a, b, c]
 */
const getReOrderLabels = (tagType, originTags) => {
  if (tagType && originTags?.length > 1 && originTags?.some(v => v.tagType === tagType)) {
    const sliceIndex = originTags.findIndex(v => v.tagType === tagType)
    const tags = originTags.slice(sliceIndex).concat(originTags.slice(0, sliceIndex))
    return tags
  }
  return originTags || []
}

onMounted(() => {
  daEventCenterSelectGuideAddOn()
  allowTouchMove.value = true
})

onUnmounted(() => {
  scrollEventHandle?.cancel?.()
})
</script>
<style lang="less">
.cart-list__item{
  background: #fff;
  position: relative;
  /* 设置 Swiper 容器样式 */
  .cart-swiper {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    // overscroll-behavior: none;
    scroll-timeline: --scroller x;
    width: 100%;
    &.not-allow__touch-move{
      overflow: hidden;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  /* 设置滑块 slide 样式 */
  .cart-swiper-slide {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    &.item-content{
      width: 100%;
    }
  }
}
.cart-list__item-container{
  background: #fff;
  padding: 20/75rem var(--item-gap, 0.2667rem);
  display: flex;
  position: relative;
  width: 101%;
  .cart-list__item-checkbox{
    height: var(--checkbox-height, 1.973rem);
    display: flex;
    justify-content: center;
  }
  .cart-list__item-content{
    flex: 1;
    min-width: 0;
    .select-guide-add-on-floor {
      margin-top: 16/75rem;
    }
  }
  .batch-checkbox{
    display: flex;
    align-items: center;
    height: var(--checkbox-height, 1.973rem);
  }
}
.operation-tab{
  display: flex;
  margin-left: 1px; // 解决 ssr 首屏红线问题
  width: auto;
  height: auto;
  .opertation-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16/75rem 8/75rem;
    font-size: 12px;
    font-weight: 700;
    width: 120/75rem;
    &.delete {
      color: @sui_color_white;
      background: @sui_color_unusual;
    }
    &.share {
      color: @sui_color_white;
      background: #FA6338;
    }
    &.similar {
      color: @sui_color_white;
      background: #FFCE00;
    }
    &.wish {
      color: @sui_color_white;
      background: #FA6338;
    }
    &.move-to-wishlist {
      color: @sui_color_white;
      background: #FF9000;
    }
    .opertation-text {
      max-height: 100%;
      overflow: hidden;
      word-break: break-word;
      text-align: center;
    }
  }
}
</style>
