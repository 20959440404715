import { getCurrentInstance } from 'vue'

const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  let _proxy = {}
  if(typeof window !== 'undefined') {
    _proxy = window._gb_app_ || {}
  }
  return {
    router: vm.proxy.$router || _proxy.$router,
    route: vm.proxy.$route || _proxy.$route,
    routerGoWish: vm.proxy.$routerGoWish,
    routerPush: vm.proxy.$routerPush,
  }
}

export { useRouter }
