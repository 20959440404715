
import debug from './useDebug'

/**
 * 气泡管理基类
 */
export default class BasePopoverManager {
  constructor({ priority = [], globalConditions = null } = {}) {
    // 是否打印日志
    this.debug = debug

    // 气泡优先级
    this.priority = priority

    // 当前注册的气泡实例和它们的状态
    this.registeredPopovers = new Map()

    // 全局控制变量，例如引导或弹窗的状态
    this.globalConditions = globalConditions || {
      isGuideOpen: true,
      isModalOpen: true,
    }

    // 气泡打开订阅者
    this.listeners = []

    // 异步任务队列
    this.taskQueue = []

    // 当前任务
    this.currentTask = null


    if (new.target === BasePopoverManager) {
      throw new Error('BasePopoverManager cannot be instantiated directly')
    }
  }

  // 注册/更新气泡
  registerPopover({ type, isAsync = false, isKeep = false, isActive = false }) {
    if (!this.priority.includes(type)) {
      this.debugLog(`注册气泡[${type}]失败，未定义气泡优先级`)
      return
    }

    const popover = this.registeredPopovers.get(type)
    if (!popover) {
      this.registeredPopovers.set(type, {
        isLoading: isAsync,
        isActive,
        isKeep,
      })
      this.debugLog(`气泡[${type}]已注册${isAsync ? '[异步]' : isKeep ? '[常驻]' : ''}`)
    } else {
      this.debugLog(`重复注册气泡[${type}]`)
    }
  }

  // 检查是否可以调度任务队列
  canProcessTasks() {
    if (this.globalConditions.isGuideOpen || this.globalConditions.isModalOpen) {
      this.debugLog(`气泡全局状态限制`)
      return false
    }

    if(Array.from(this.registeredPopovers.values()).some(({ isLoading }) => isLoading)) {
      this.debugLog(`气泡异步状态限制`)
      return false
    }

    return true
  }

  // 更新异步气泡的加载状态
  updateAsyncPopoverState(type) {
    const popover = this.registeredPopovers.get(type)
    if (!popover) {
      this.debugLog(`气泡[${type}]异步状态更新失败，未注册`)
      return
    }
    if (!popover.isLoading) {
      this.debugLog(`气泡[${type}]异步状态更新重复调用`)
      return
    }
    popover.isLoading = false

    this.debugLog(`气泡[${type}]异步状态更新，重新调用异步队列`)
    this.scheduleTasks()
  }

  // 打开气泡
  openPopover(task) {
    const popover = this.registeredPopovers.get(task.type)
    this.currentTask = task
    popover.isActive = true
    task?.complete?.(true)
    this.notifyListeners()
    this.debugLog(`气泡[${task.type}]激活`)
  }

  // 关闭气泡
  closePopover(type, callback) {
    const popover = this.registeredPopovers.get(type)
    if (!popover) {
      this.debugLog(`气泡[${type}]无法关闭，未注册`)
      return
    }

    popover.isActive = false
    callback?.(false)
    this.debugLog(`气泡[${type}]关闭`)

    if (type === this.currentTask?.type) {
      this.debugLog(`任务队列气泡[${type}]关闭,重新触发调用队列`)
      this.currentTask = null
      this.scheduleTasks()
    } else {
      // 外界提前关闭的气泡还在队列中的气泡
      this.removeTask(type)
    }
  }

  // 获取当前打开的气泡
  getOpenPopovers() {
    return Array.from(this.registeredPopovers.entries()).filter(([_, state]) => state.isActive)
  }

  addTask(task) {
    if (!this.findTask(task.type)) {
      this.taskQueue.push(task)
    }
    this.debugLog(`气泡${task.type}添加异步任务队列`)
    
    // 等待另外一个微任务
    setTimeout(() => this.scheduleTasks(), 0)
  }

  findTask(type) {
    return this.taskQueue.find(task => task.type === type)
  }

  // 移除任务
  removeTask(type) {
    const index = this.taskQueue.findIndex(task => task.type === type)
    if (index >= 0) {
      this.taskQueue.splice(index, 1)
      this.debugLog(`任务队列气泡[${type}]关闭,从任务队列移除`)
    }
  }

  // 调度异步任务队列
  scheduleTasks() {
    if (this.currentTask) {
      this.debugLog(`气泡异步任务队列,正在冲洗退出调用`)
      return
    }
    if (!this.taskQueue.length) {
      this.debugLog(`气泡异步任务队列,队列为空退出调用`)
      return
    }
    // 使用微任务调度
    Promise.resolve().then(() => {
      this.debugLog(`气泡调度异步任务队列开始`)
      this.flushTasks()
    })
  }

  // 按优先级处理任务队列
  flushTasks() {
    const [firstTask, ...pendingTask] = this.taskQueue.sort((a, b) => this.priority.indexOf(a.type) - this.priority.indexOf(b.type))
    if (this.canProcessTasks() && firstTask) {
      this.taskQueue = pendingTask
      this.openPopover(firstTask)
    }
  }

  // 添加监听器
  addListener(callback) {
    if (!this.listeners.includes(callback)) {
      this.listeners.push(callback);
    }
  }
  
  // 通知所有监听器
  notifyListeners() {
    this.listeners.forEach((callback) => callback())
    // 直接清空简单实现只订阅一次
    this.listeners = []
  }

  // 设置全局控制条件
  setGlobalCondition(conditionKey, value) {
    if (conditionKey in this.globalConditions) {
      this.globalConditions[conditionKey] = value
    }
  }

  // 打印日志
  debugLog(...msg) {
    if (this.debug && typeof window !== 'undefined') {
      console.warn(...msg)
    }
  }

  /**
   * 销毁
   */
  destroy() {
    this.registeredPopovers.clear()
    this.listeners = []
    this.taskQueue = []
    this.currentTask = null
  }
}